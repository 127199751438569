/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../style/color';
import { fontFamilyRegular, borderRadius, transitionDefault } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  label: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    fontSize: '14px',
    padding: '8px 0',
    color: Theme2.GrayXd,
    display: 'block',
  },
  input: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    color: Theme2.Black,
    display: 'block',
    width: '100%',
    padding: '16px',
    fontSize: '14px',
    lineHeight: '1.5',
    backgroundColor: Theme2.White,
    backgroundImage: 'none',
    backgroundClip: 'padding-box',
    border: '1px solid #E6E7E7',
    borderRadius,
    outline: 'none',
    transition: transitionDefault,
    ':hover': {
      border: `1px solid ${Theme2.GrayL}`,
    },
    ':focus': {
      border: `1px solid ${Theme2.GrayXd}`,
    },
    '::placeholder': {
      color: Theme2.GrayD,
    },
  },
  noOutline: {
    border: 'none',
    ':hover': {
      border: 'none',
    },
    ':focus': {
      border: 'none',
    },
  },
  inputError: {
    backgroundColor: Theme2.PrimaryXl,
    border: `1px solid ${Theme2.PrimaryXd}`,
    ':focus': {
      border: `1px solid ${Theme2.PrimaryXd}`,
    },
    ':hover': {
      border: `1px solid ${Theme2.PrimaryXd}`,
    },
  },
  inputDisabled: {
    color: Theme2.GrayD,
    backgroundColor: Theme2.GrayXl,
    border: `1px solid ${Theme2.GrayL}`,
    ':focus': {
      border: `1px solid ${Theme2.GrayL}`,
    },
    ':hover': {
      border: `1px solid ${Theme2.GrayL}`,
    },
  },

  helper: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    fontSize: '12px',
    padding: '4px 0',
    color: Theme2.Black,
  },
  textLight: {
    color: Theme2.White,
  },
  textLight60: {
    color: Theme2.White60,
  },
  textDark: {
    color: Theme2.Black,
  },
  textError: {
    color: Theme2.PrimaryXd,
  },
  textDisabled: {
    color: Theme2.Black40,
  },
  relative: {
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  smallWidth: {
    width: '30%',
    minWidth: '70px',
  },
});
