/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  children: any,
  style?: Array<any>,
  size?: 'large' | 'medium' | 'small' | 'xlarge' | 'none',
  color?: 'white' | 'gray' | 'black' | 'primary' | 'grayxd',
  border?: 'top' | 'bottom' | 'both',
  noPaddingBottom?: Boolean,
  image: string,
  targetRef?: any,
};

const Section = (props: Props) => (
  <div
    ref={props.targetRef}
    className={css(
      Style.base,
      props.size === 'xlarge' && Style.xlarge,
      props.size === 'large' && Style.large,
      props.size === 'medium' && Style.medium,
      props.size === 'small' && Style.small,
      props.color === 'white' && Style.white,
      props.color === 'gray' && Style.gray,
      props.color === 'grayxd' && Style.grayxd,
      props.color === 'black' && Style.black,
      props.color === 'primary' && Style.primary,
      props.border === 'top' && Style.borderTop,
      props.border === 'bottom' && Style.borderBottom,
      props.border === 'both' && Style.borderBoth,
      props.noPaddingBottom && Style.noPaddingBottom,
      ...props.style
    )}
    style={{
      backgroundImage: `url(${props.image})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    {props.children}
  </div>
);

Section.defaultProps = {
  style: [],
  size: 'large',
  color: 'white',
  image: '',
  border: undefined,
  targetRef: undefined,
};

export default Section;
