import { Theme2 } from '../style/color';

/* @flow */
import Font from '../style/font';

// Border radius used in boxes and buttons
export const borderRadius = '8px';
export const borderColor = Theme2.GrayXd10;

// Transitions for animations
export const transitionDefault = '0.3s';
export const transitionSlow = '0.8s';
export const transitionFast = '0.17s';

// Z-Index Presets
export const zIndexLow = '1';
export const zIndexMedium = '500';
export const zIndexHigh = '1001';

// Box Shadow Presets
export const boxShadowDown = '0 1px 2px 0 rgba(13,32,47,0.07)';
export const boxShadowMedium = '0 6px 24px 0 rgba(13,32,47,0.10)';
export const boxShadowUp = '0 12px 54px 0 rgba(13,32,47,0.10)';

// Breakpoints
export const xs = 320;
export const sm = 480;
export const md = 768;
export const lg = 992;
export const xl = 1200;

// Default font family
export const fontFamilyRegular = [Font.CircularBook, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'];
export const fontFamilyMedium = [Font.CircularMedium, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'];
export const fontSignature = [Font.Signature, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'];
