/* @flow */
import gql from 'graphql-tag';

/**
 * Phone recovery
 */
export default gql`
  mutation PhoneRecovery($input: PhoneRecoveryInput!) {
    phoneRecovery(input: $input) {
      success
    }
  }
`;
