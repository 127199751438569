/* @flow */

import includes from 'lodash/includes';
import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  id?: string,
  btnType?: string,
  style?: Array<any>,
  type?:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'light'
    | 'border'
    | 'border-white'
    | 'border-gray-d'
    | 'border-red'
    | 'border-red2'
    | 'border-red3'
    | 'no-border-light'
    | 'border-white-fill',
  display?: 'inline' | 'block' | 'flex' | 'none',
  width?: 'auto' | 'fullwidth',
  size?: 'normal' | 'small' | 'extra-small',
  black?: boolean,
  responsive?: boolean,
  disabled?: boolean,
  roundedBorder?: boolean,
  social?: 'facebook',
  label: string | Object,
  onClick?: () => any,
  onMouseDown?: () => any,
  children?: Object,
};

const Button = (props: Props) => {
  return (
    <button
      id={props.id}
      type={props.btnType}
      onClick={props.disabled ? undefined : props.onClick}
      onMouseDown={props.onMouseDown}
      className={css(
        Style.base,
        props.type === 'primary' && Style.primary,
        props.type === 'secondary' && Style.secondary,
        props.type === 'light' && Style.light,
        props.type === 'ghost' && Style.ghost,
        props.type === 'border' && Style.border,
        props.type === 'border-white' && Style.borderWhite,
        props.type === 'border-gray-d' && Style.borderGrayD,
        props.type === 'border-red' && Style.borderRed,
        props.type === 'border-red2' && Style.borderRedCheck,
        props.type === 'border-red3' && Style.borderRedCheckSm,
        props.type === 'no-border-light' && Style.noBorderLight,
        props.type === 'border-white-fill' && Style.borderWhiteFill,
        props.display === 'inline' && Style.inline,
        props.display === 'block' && Style.block,
        props.display === 'flex' && Style.flex,
        props.display === 'none' && Style.none,
        props.width === 'auto' && Style.autowidth,
        props.width === 'fullwidth' && Style.fullwidth,
        props.social === 'facebook' && Style.facebook,
        props.size === 'small' && Style.small,
        props.size === 'extra-small' && Style.extraSmall,
        props.black && Style.black,
        props.responsive && Style.mobilefullwidth,
        props.responsive && Style.mobileNoBorderRadiusSM,
        props.roundedBorder && Style.roundedBorder,
        props.disabled && Style.disabled,
        props.disabled &&
          includes(
            ['border', 'border-white', 'border-gray-d', 'border-red', 'border-red2', 'border-red3'],
            props.type
          ) &&
          Style.disabledBorder,
        ...props.style
      )}
    >
      {props.children}
      {props.label}
    </button>
  );
};

Button.defaultProps = {
  btnType: undefined,
  style: [],
  type: 'primary',
  display: 'inline',
  width: 'auto',
  social: undefined,
  responsive: false,
  disabled: false,
  size: 'normal',
  onClick: undefined,
};

export default Button;
