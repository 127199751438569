/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  style?: Array<any>,
  color?: 'default' | 'light' | 'dark' | 'grey',
  logomark?: boolean,
  responsiveMobile?: boolean,
  mobileSize?: boolean,
};

const Logo = (props: Props) => (
  <div
    className={css(
      Style.base,
      props.responsiveMobile && Style.mobileBase,
      props.mobileSize && Style.mobileSize,
      props.logomark && Style.logomark,
      props.color === 'light' && Style.colorWhite,
      props.color === 'light' && props.responsiveMobile && Style.mobileColorWhite,
      props.color === 'dark' && Style.colorDark,
      props.color === 'dark' && props.responsiveMobile && Style.mobileColorDark,
      props.color === 'grey' && Style.colorGrey,
      ...props.style
    )}
  />
);

Logo.defaultProps = {
  style: [],
  color: 'default',
  logomark: false,
  responsiveMobile: false,
  mobileSize: false,
};

export default Logo;
