/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import DropdownStyle from './style';

type Props = {
  children: any,
};

const DropdownContainer = (props: Props) => (
  <div className={css(
    DropdownStyle.dropdownContainer,
  )}
  >
    {props.children}
  </div>
);

export default DropdownContainer;
