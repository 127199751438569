/* @flow */

import React from 'react';
import { css } from 'aphrodite';
import _ from 'underscore';

import FormItem from '../form_item/FormItem';
import List from '../list/List';
import Dropdown from '../dropdown/Dropdown';
import ListItem from '../list/ListItem';

import Style from './style';
import ListStyle from '../list/style';
import Spacing from '../../style/spacing';

type Props = {
  id?: string,
  disabled?: boolean,
  spacingbottom?: boolean,
  fullWidth?: boolean,
  smallWidth?: boolean,
  type?: string,
  placeholder?: string,
  value?: string,
  pattern?: string,
  inputStyle?: Array<any>,

  helperText?: string,
  labelColor?: 'light' | 'dark' | 'error',
  labelText?: string,
  helperColor?: 'light' | 'dark' | 'error',

  noOutline?: boolean,

  inputError?: boolean,

  options?: Array<{ id: string, label: string }>,

  onClick?: (evt: any) => void,
  onFocus?: (evt: any) => void,
  onBlur?: (evt: any) => void,
  onChange?: (evt: any) => void,
  onKeyUp?: (evt: any) => void,
  onKeyDown?: (evt: any) => void,
  onSelectResult?: (id: string) => void,
  onPaste?: (evt: any) => void,
  inpRef?: (c: any) => void,
};

type State = {
  showMenu: boolean,
  canHideMenu: boolean,
};

class Input extends React.Component<Props, State> {
  static defaultProps = {
    id: undefined,
    disabled: false,
    type: 'text',
    placeholder: '',
    value: undefined,
    pattern: undefined,
    spacingbottom: false,

    labelText: undefined,
    labelColor: undefined,

    // Used to show smth below input (e.g. errors when ”inputError” is true)
    helperText: undefined,
    helperColor: undefined,

    noOutline: false,
    fullWidth: false,
    smallWidth: false,

    inputError: false,

    options: undefined,

    onFocus: undefined,
    onBlur: undefined,
    onChange: undefined,
    onKeyUp: undefined,
    onKeyDown: undefined,
    onSelectResult: undefined,
    inpRef: undefined,
    inputStyle: [],
    onPaste: undefined,
  };

  state = {
    showMenu: false,
    canHideMenu: true,
  };

  inputRef: any;

  handleOnChange(evt: Event) {
    this.setState({
      showMenu: true,
    });

    if (this.props.onChange) {
      this.props.onChange(evt);
    }

    if (this.props.onClick) {
      this.props.onClick(evt);
    }
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  render() {
    return (
      <FormItem
        style={[
          this.props.spacingbottom && Spacing.mb8,
          this.props.fullWidth && Style.fullWidth,
          this.props.smallWidth && Style.smallWidth,
        ]}
      >
        {/* Label */}
        {this.props.labelText &&
          <label
            htmlFor={this.props.id}
            className={css(
              Style.label,
              (this.props.labelColor === 'light') && Style.textLight60,
              (this.props.labelColor === 'dark') && Style.textDark,
              (this.props.labelColor === 'error') && Style.textError,
            )}
          >
            {this.props.labelText || ''}
          </label>
        }

        {/* Input field and dropdown contrainer */}
        <div className={css(Style.relative)}>
          <input
            ref={(c) => {
              this.inputRef = c;
              if (this.props.inpRef) {
                this.props.inpRef(c);
              }
            }}
            data-hj-whitelist
            id={this.props.id}
            disabled={this.props.disabled}
            type={this.props.type}
            placeholder={this.props.placeholder}
            value={this.props.value}
            pattern={this.props.pattern}
            className={css(
              Style.input,
              this.props.noOutline && Style.noOutline,
              this.props.inputError && Style.inputError,
              this.props.disabled && Style.inputDisabled,
              ...this.props.inputStyle,
            )}
            onChange={evt => this.handleOnChange(evt)}
            onKeyUp={this.props.onKeyUp}
            onKeyDown={this.props.onKeyDown}
            onFocus={(evt) => {
              if (this.props.onFocus) this.props.onFocus(evt);
              this.setState({
                showMenu: true,
                canHideMenu: false,
              }, () => setTimeout(() => {
                this.setState({ canHideMenu: true });
              }, 50));
            }}
            onBlur={this.props.onBlur}
            onClick={this.props.onClick}
            onPaste={this.props.onPaste}
          />

          {/* Options dropdown */}
          {this.state.showMenu && this.inputRef && this.inputRef.value.trim() !== '' && this.props.options !== undefined &&
            <Dropdown>
              <List>
                {_.map(this.props.options, opt =>
                  (<ListItem
                    onClick={() => this.props.onSelectResult && this.props.onSelectResult(opt.id)}
                    key={`item-${opt.id}`}
                    style={[ListStyle.dropdownListItem, ListStyle.textNoWrap]}
                  >
                    {opt.label}
                  </ListItem>),
                )}
              </List>
            </Dropdown>
          }
        </div>

        {/* Helper text */}
        {this.props.helperText &&
          <div className={css(
            Style.helper,
            (this.props.helperColor === 'light') && Style.textLight,
            (this.props.helperColor === 'dark') && Style.textDark,
            (this.props.helperColor === 'error') && Style.textError,
          )}
          >
            {this.props.helperText || ''}
          </div>
        }
      </FormItem>
    );
  }
}

export default Input;
