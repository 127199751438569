/* @flow */

import { StyleSheet } from 'aphrodite';
import reduce from 'lodash/reduce';
import { sm } from './variables';

const sizes = [0, 2, 4, 8, 12, 16, 18, 22, 24, 32, 36, 40, 44, 48, 52, 58, 60, 68, 76, 84, 92, 100];

const sizeValues = reduce(
  sizes,
  (m, v) => ({
    ...m,
    [`ml${v}`]: { marginLeft: `${v}px` },
    [`mb${v}`]: { marginBottom: `${v}px` },
    [`mt${v}`]: { marginTop: `${v}px` },
    [`mr${v}`]: { marginRight: `${v}px` },
    [`m${v}`]: { margin: `${v}px` },
    [`p${v}`]: { padding: `${v}px` },
    [`pl${v}`]: { paddingLeft: `${v}px` },
    [`pb${v}`]: { paddingBottom: `${v}px` },
    [`pt${v}`]: { paddingTop: `${v}px` },
    [`pr${v}`]: { paddingRight: `${v}px` },
  }),
  {}
);

const smSizeValues = reduce(
  sizeValues,
  (obj, v, k) => ({
    ...obj,
    [`sm${k}`]: {
      [`@media (max-width: ${sm}px)`]: {
        ...v,
      },
    },
  }),
  {}
);

export default StyleSheet.create({
  ...sizeValues,
  ...smSizeValues,
});
