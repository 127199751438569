/* @flow */

import RubikLightFont from '../fonts/Rubik-Light.ttf';
import RubikRegularFont from '../fonts/Rubik-Regular.ttf';
import RubikMediumFont from '../fonts/Rubik-Medium.ttf';
import RubikBoldFont from '../fonts/Rubik-Bold.ttf';

import SignatureFont from '../fonts/Otella.ttf';

import CircularBookEOT from '../fonts/Circular/LL-Circular-Book-Web/fonts/lineto-circular-book.eot';
import CircularBookWOFF from '../fonts/Circular/LL-Circular-Book-Web/fonts/lineto-circular-book.woff';
import CircularBookWOFF2 from '../fonts/Circular/LL-Circular-Book-Web/fonts/lineto-circular-book.woff2';

import CircularBookSEOT from '../fonts/Circular/LL-Circular-Book-Web/fonts_subsetted/lineto-circular-book.eot';
import CircularBookSWOFF from '../fonts/Circular/LL-Circular-Book-Web/fonts_subsetted/lineto-circular-book.woff';
import CircularBookSWOFF2 from '../fonts/Circular/LL-Circular-Book-Web/fonts_subsetted/lineto-circular-book.woff2';

import CircularMediumEOT from '../fonts/Circular/LL-Circular-Medium-Web/fonts/lineto-circular-medium.eot';
import CircularMediumWOFF from '../fonts/Circular/LL-Circular-Medium-Web/fonts/lineto-circular-medium.woff';
import CircularMediumWOFF2 from '../fonts/Circular/LL-Circular-Medium-Web/fonts/lineto-circular-medium.woff2';

import CircularMediumSEOT from '../fonts/Circular/LL-Circular-Medium-Web/fonts_subsetted/lineto-circular-medium.eot';
import CircularMediumSWOFF from '../fonts/Circular/LL-Circular-Medium-Web/fonts_subsetted/lineto-circular-medium.woff';
import CircularMediumSWOFF2 from '../fonts/Circular/LL-Circular-Medium-Web/fonts_subsetted/lineto-circular-medium.woff2';

const RubikLight = {
  fontFamily: 'Rubik',
  fontWeight: 300,
  src: `url(${RubikLightFont})`,
};

const RubikRegular = {
  fontFamily: 'Rubik',
  fontWeight: 400,
  src: `url(${RubikRegularFont})`,
};

const RubikMedium = {
  fontFamily: 'Rubik',
  fontWeight: 500,
  src: `url(${RubikMediumFont})`,
};

const RubikBold = {
  fontFamily: 'Rubik',
  fontWeight: 700,
  src: `url(${RubikBoldFont})`,
};

const CircularBook = {
  fontFamily: 'Circular-Book',
  fontWeight: 'normal',
  fontDisplay: 'swap',
  src: `url(${CircularBookEOT}) format('embedded-opentype'), url(${CircularBookWOFF2}) format('woff2'), url(${CircularBookWOFF}) format('woff')`,
};

const CircularBookS = {
  fontFamily: 'Circular-Book-S',
  fontWeight: 'normal',
  src: `url(${CircularBookSEOT}) format('embedded-opentype'), url(${CircularBookSWOFF2}) format('woff2'), url(${CircularBookSWOFF}) format('woff')`,
};

const CircularMedium = {
  fontFamily: 'Circular-Book',
  fontWeight: 500,
  src: `url(${CircularMediumEOT}) format('embedded-opentype'), url(${CircularMediumWOFF2}) format('woff2'), url(${CircularMediumWOFF}) format('woff')`,
};

const CircularMediumS = {
  fontFamily: 'Circular-Book-S',
  fontWeight: 500,
  src: `url(${CircularMediumSEOT}) format('embedded-opentype'), url(${CircularMediumSWOFF2}) format('woff2'), url(${CircularMediumSWOFF}) format('woff')`,
};

const Signature = {
  fontFamily: 'Otella',
  src: `url(${SignatureFont})`,
};

export default {
  RubikLight,
  RubikRegular,
  RubikMedium,
  RubikBold,
  CircularBook,
  CircularBookS,
  CircularMedium,
  CircularMediumS,
  Signature,
};
