/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  style?: Array<any>,
  size?: 'small' | 'medium' | 'large' | 'xlarge',
  padding?: 'small' | 'medium' | 'large' | 'xlarge',
  display?: 'block' | 'inline' | 'flex',
  color?: 'white' | 'whitemuted' | 'black' | 'blackmuted' | 'blackextramuted' | 'gray' | 'graymuted' | 'primary',
  hover?: boolean,
  onPress?: () => void,
  clickable?: boolean,
  fontValue: string,
};

const Icon = (props: Props) => (
  <span
    onClick={() => props.onPress && props.onPress()}
    className={css(
      Style.base,
      Style[props.fontValue],
      (props.size === 'small') && Style.small,
      (props.size === 'medium') && Style.medium,
      (props.size === 'large') && Style.large,
      (props.size === 'xlarge') && Style.xlarge,
      (props.padding === 'small') && Style.smallPad,
      (props.padding === 'medium') && Style.mediumPad,
      (props.padding === 'large') && Style.largePad,
      (props.padding === 'xlarge') && Style.xlargePad,
      (props.display === 'block') && Style.block,
      (props.display === 'inline') && Style.inline,
      (props.display === 'flex') && Style.flex,
      (props.color === 'white') && Style.white,
      (props.color === 'whitemuted') && Style.whiteMuted,
      (props.color === 'black') && Style.black,
      (props.color === 'blackmuted') && Style.blackMuted,
      (props.color === 'blackextramuted') && Style.blackExtraMuted,
      (props.color === 'gray') && Style.gray,
      (props.color === 'graymuted') && Style.grayMuted,
      (props.color === 'primary') && Style.primary,
      props.hover && Style.hover,
      props.clickable && Style.cursorPointer,
      ...props.style,
    )}
  />
);

Icon.defaultProps = {
  style: [],
  size: 'medium',
  display: 'inline',
  color: 'black',
  padding: undefined,
  hover: false,
  onPress: () => undefined,
  clickable: undefined,
};

export default Icon;
