/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Icon from '../../../../components/icon/Icon';
import Link from '../../../../components/link/Link';
import Responsive from '../../../../style/responsive';
import Spacing from '../../../../style/spacing';
import Typography from '../../../../components/typography/Typography';
import TypographyStyle from '../../../../components/typography/style';

import StepContentStyle from './style';

type Props = {
  children: any,
  backbutton?: boolean,
  goBack?: () => any,
  size?: 'normal' | 'small' | 'large',
};

/**
 * StepContent
 */
const StepContent = (props: Props) => (
  <div
    className={css(
      StepContentStyle.boxContainer,
      props.size === 'small' && StepContentStyle.boxContainerSmall,
      props.size === 'large' && StepContentStyle.boxContainerLarge,
    )}
  >
    {props.backbutton &&
      <div className={css(Responsive.hiddenMd)}>
        <div className={css(StepContentStyle.backArrow)}>
          <Link
            id="backButton"
            onClick={() => {
              if (props.goBack) {
                props.goBack();
              }
            }}
          >
            <Icon
              fontValue="TailLeft"
              color="graymuted"
              size="large"
              padding="large"
            />
          </Link>
        </div>
      </div>
    }
    {props.children}

    {props.backbutton &&
      <div className={css(Responsive.visibleMd, Spacing.mt16, Spacing.mb16)}>
        <Link
          onClick={() => {
            if (props.goBack) {
              props.goBack();
            }
          }}
        >
          <Icon
            fontValue="TailLeft"
            color="graymuted"
            size="large"
            padding="large"
          />
          <Typography.ParagraphSmall
            style={[
              TypographyStyle.textLeft,
              TypographyStyle.colorBlackMuted,
              TypographyStyle.noMarginBottom,
              TypographyStyle.inline,
            ]}
          >
            Previous step
          </Typography.ParagraphSmall>
        </Link>
      </div>
    }
  </div>
);

StepContent.defaultProps = {
  backbutton: false,
  goBack: undefined,
  size: 'normal',
};

export default StepContent;
