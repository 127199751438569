/* @flow */

import { StyleSheet } from 'aphrodite';
import { Theme2 } from '../../../../style/color';
import { transitionDefault, lg, md } from '../../../../style/variables';

const container = {
  minHeight: 'calc(100vh - 160px)',
  padding: '62px 0',
  [`@media (max-width: ${md}px)`]: {
    padding: '32px 0',
  },
};

const headerContainer = {
  paddingBottom: '48px',
  [`@media (max-width: ${md}px)`]: {
    paddingBottom: '24px',
  },
};

const boxContainer = {
  margin: '0 auto',
  maxWidth: '496px',
  width: '100%',
  position: 'relative',
};

const boxContainerSmall = {
  margin: '0 auto',
  maxWidth: '400px',
  width: '100%',
  position: 'relative',
};

const boxContainerLarge = {
  margin: '0 auto',
  maxWidth: '800px',
  width: '100%',
  position: 'relative',
};

const backArrow = {
  position: 'absolute',
  left: '-72px',
  top: '50%',
  transform: 'translateY(-50%)',
};

const containerRadioFirst = {
  borderRadius: '8px 8px 0 0',
};
const containerRadio = {
  padding: '32px',
  cursor: 'pointer',
  transition: transitionDefault,
  borderBottom: `1px solid ${Theme2.GrayXl}`,
  ':hover': {
    backgroundColor: '#ECEEEF',
  },
  [`@media (max-width: ${md}px)`]: {
    padding: '16px',
  },
};

const boxPadding = {
  padding: '32px',
  [`@media (max-width: ${md}px)`]: {
    padding: '16px',
  },
};

const containerRadioLast = {
  padding: '32px',
  borderBottom: 'none',
  borderRadius: '0 0 8px 8px',
  [`@media (max-width: ${md}px)`]: {
    padding: '16px',
  },
};

const skipStepLink = {
  width: '100%',
  [`@media (max-width: ${md}px)`]: {
    paddingTop: '16px',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '8px',
  },
};
const skipStepContainer = {
  [`@media (max-width: ${md}px)`]: {
    width: '100%',
  },
};

const containerStepSkip = {
  padding: '32px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${Theme2.GrayXl}`,
  [`@media (max-width: ${md}px)`]: {
    flexDirection: 'column',
    padding: '16px',
  },
};

const insuranceListItem = {
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${Theme2.GrayXl}`,
  paddingTop: '16px',
  paddingBottom: '16px',
};

const selectCardSubtitle = {
  color: Theme2.GrayD,
  textAlign: 'left',
  marginBottom: '4px',
  [`@media (min-width: ${md}px)`]: {
    minHeight: '96px',
  },
  [`@media (min-width: ${lg}px)`]: {
    minHeight: '80px',
  },
};

export default StyleSheet.create({
  container,
  boxContainer,
  backArrow,
  containerRadio,
  containerRadioLast,
  containerRadioFirst,
  containerStepSkip,
  headerContainer,
  skipStepLink,
  boxPadding,
  insuranceListItem,
  skipStepContainer,
  boxContainerSmall,
  boxContainerLarge,
  selectCardSubtitle,
});
