/* eslint-disable react/prop-types */
import React from 'react';
import { css } from 'aphrodite';

import { useMutation } from '@apollo/client';
import { observer, Observer } from 'mobx-react';
import { observable } from 'mobx';

import Section from '../../../../components/section/Section';
import Typography from '../../../../components/typography/Typography';
import TypographyStyle from '../../../../components/typography/style';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button3/Button';
import Spacing from '../../../../style/spacing';
import StepContainer from '../../../signup4/components/stepparts/StepContainer';
import StepContent from '../../../signup4/components/stepparts/StepContent';

import { validNumeric } from '../../../../../common/validators';
import { formatPhone, unformatPhone } from '../../../../../common/utils/format';

import PHONE_RECOVERY_MUTATION from './mutations/phone_recovery';
import PHONE_LOGIN_MUTATION from './mutations/phone_login';
import flex from '../../../../style/flex';

/**
 * Login
 */
@observer
class Index extends React.Component<any, any> {
  @observable phone = '';
  @observable code = '';
  @observable busy = false;
  @observable error: string | null = null;
  @observable phoneDone = false;
  @observable codeDone = false;

  onSubmit = async (evt: any, update: any) => {
    evt.preventDefault();

    this.busy = true;
    this.error = null;

    const input: any = { phone: this.phone };
    if (this.phoneDone) {
      input.code = this.code;
    }

    const resp = await update({
      variables: {
        input,
      },
    });

    this.busy = false;

    // Report errors
    const { data } = resp;
    if ((data.errors || []).length > 0) {
      this.error = data.errors[0].message;
      return;
    }

    if (!this.phoneDone) {
      this.phoneDone = true;
    } else {
      // Redirect
      window.location.href = this.props.redirectURL;
    }
  };

  LoginMutations = ({ onSubmit, btnDisabled, phoneError, codeError }) => {
    const [recoveryMutation] = useMutation(PHONE_RECOVERY_MUTATION);
    const [loginMutation] = useMutation(PHONE_LOGIN_MUTATION);

    return (
      <Observer>
        {() => (
          <form onSubmit={(evt) => onSubmit(evt, this.phoneDone ? loginMutation : recoveryMutation)}>
            <Input
              placeholder="(XXX) XXX XXXX"
              value={formatPhone(this.phone)}
              onChange={(evt) => {
                this.phone = unformatPhone(evt.target.value);
              }}
              helperText={phoneError || undefined}
              helperColor="error"
              disabled={this.phoneDone}
            />
            {this.phoneDone && (
              <div className={css(Spacing.mt16)}>
                <Input
                  style={[Spacing.mt16]}
                  placeholder="_ _ _ _"
                  value={this.code}
                  onChange={(evt) => {
                    this.code = evt.target.value.replace(/[^0-9]/gi, '').substr(0, 4);
                  }}
                  helperText={codeError || undefined}
                  helperColor="error"
                />
              </div>
            )}
            <div className={css(flex.base)}>
              {this.phoneDone && (
                <Button
                  btnType="button"
                  secondary
                  type="border"
                  label="Cancel"
                  width="fullwidth"
                  style={[Spacing.mt16, Spacing.mr8]}
                  disabled={this.busy}
                  onClick={() => {
                    this.phoneDone = false;
                  }}
                />
              )}
              <Button label="Continue" width="fullwidth" style={[Spacing.mt16]} disabled={btnDisabled} />
            </div>
          </form>
        )}
      </Observer>
    );
  };

  render() {
    let btnDisabled = this.busy;
    let phoneError: any;
    let codeError: any;
    if (this.phoneDone) {
      btnDisabled = btnDisabled || !validNumeric(this.code, 4);
      codeError = this.error;
    } else {
      btnDisabled = btnDisabled || !validNumeric(this.phone, 10);
      phoneError = this.error;
    }

    return (
      <div>
        <StepContainer>
          <Section size="small">
            <StepContent size="small">
              <div className="container">
                {/* Header */}
                <Typography.H3
                  style={[
                    TypographyStyle.textCenter,
                    TypographyStyle.colorBlack,
                    TypographyStyle.textBold,
                    TypographyStyle.noMarginBottom,
                    Spacing.mb16,
                  ]}
                >
                  Please login
                </Typography.H3>
                <Typography.ParagraphSmall style={[TypographyStyle.textCenter, TypographyStyle.colorBlackMuted]}>
                  Login code will be sent by text
                </Typography.ParagraphSmall>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col">
                    <this.LoginMutations
                      onSubmit={this.onSubmit}
                      btnDisabled={btnDisabled}
                      phoneError={phoneError}
                      codeError={codeError}
                    />
                  </div>
                </div>
              </div>
            </StepContent>
          </Section>
        </StepContainer>
      </div>
    );
  }
}

export default Index;
