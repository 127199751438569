/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  children: any,
  style?: Array<any>,
  onClick?: any,
};

const FormItem = (props: Props) => (
  <div
    className={css(
      Style.base,
      ...props.style,
    )}
    onClick={props.onClick}
  >
    {props.children}
  </div>
);

FormItem.defaultProps = {
  style: [],
  onClick: undefined,
};

export default FormItem;
