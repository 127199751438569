/* @flow */

import { StyleSheet } from 'aphrodite';

// Full Jerry logo
import LogotypePrimary from './imgs/logotype-primary.svg';
import LogotypeLight from './imgs/logotype-light.svg';
import LogotypeDark from './imgs/logotype-dark.svg';
import LogotypeGrey from './imgs/logotype-grey.svg';

// Single 'J' logo
import LogomarkPrimary from './imgs/logomark-primary.svg';
import LogomarkLight from './imgs/logomark-light.svg';
import LogomarkDark from './imgs/logomark-dark.svg';

import { md } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  base: {
    backgroundImage: `url(${LogotypePrimary})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    width: '105px',
    height: '26px',
  },
  colorWhite: {
    backgroundImage: `url(${LogotypeLight})`,
  },
  colorDark: {
    backgroundImage: `url(${LogotypeDark})`,
  },
  colorGrey: {
    backgroundImage: `url(${LogotypeGrey})`,
  },
  mobileBase: {
    [`@media (max-width: ${md}px)`]: {
      backgroundImage: `url(${LogomarkPrimary})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'block',
      width: '34px',
      height: '20px',
    },
  },
  mobileSize: {
    [`@media (max-width: ${md}px)`]: {
      display: 'block',
      width: '77px',
      height: '19px',
    },
  },
  logomark: {
    backgroundImage: `url(${LogomarkPrimary})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    width: '25px',
    height: '33px',
  },
  mobileColorWhite: {
    [`@media (max-width: ${md}px)`]: {
      backgroundImage: `url(${LogomarkLight})`,
    },
  },
  mobileColorDark: {
    [`@media (max-width: ${md}px)`]: {
      backgroundImage: `url(${LogomarkDark})`,
    },
  },
});
