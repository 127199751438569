/* @flow */

import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  /** Base style */
  base: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  justifyContentSpaceEvenly: {
    justifyContent: 'space-evenly',
  },
  directionRow: {
    flexDirection: 'row',
  },
  directionColumn: {
    flexDirection: 'column',
  },
  fullWidth: {
    width: '100%',
  },
  positionRelative: {
    position: 'relative',
  },
  flexAuto: {
    flex: '0 0 auto',
  },
});
