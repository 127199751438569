/* @flow */

import { StyleSheet } from 'aphrodite';

const base = {
  textDecoration: 'none',
  cursor: 'pointer',
};

const inheritColor = {
  color: 'inherit',
};

const inline = {
  display: 'inline-block',
};

const sameline = {
  display: 'inline',
};

const block = {
  display: 'block',
};

const underline = {
  textDecoration: 'underline',
};

const fullWidth = {
  width: '100%',
};

export default StyleSheet.create({
  base,
  inheritColor,
  inline,
  block,
  underline,
  sameline,
  fullWidth,
});
