/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  children: any,
  style?: Array<any>,
  color?: 'light' | 'dark' | 'mutedLight' | 'mutedDark',
  alignment?: 'left' | 'right' | 'center',
  order?: 'horizontal' | 'vertical',
  hover?: 'light' | 'dark',
  size?: 'small' | 'medium' | 'large' | 'xlarge',
  type?: 'none' | 'ordered' | 'bullet',
  noPadding?: boolean,
  itemScope?: boolean,
  itemType?: string,
};

const renderChildren = (
  children: Array<any>,
  order?: string,
  size?: string,
  hover?: string,
  noPadding?: boolean,
): any => React.Children.map(children, child => child && React.cloneElement(child, {
  ...child.props,
  style: [
    (order === 'vertical') && Style.vertical,
    (order === 'horizontal') && Style.horizontal,
    (size === 'small') && Style.sizeSmall,
    (size === 'medium') && Style.sizeMedium,
    (size === 'large') && Style.sizeLarge,
    (size === 'xlarge') && Style.sizeXLarge,
    (hover === 'light') && Style.hoverLight,
    (hover === 'dark') && Style.hoverDark,
    noPadding && Style.noPadding,
    ...(child.props.style || []),
  ],
}));

const List = (props: Props) => (
  <ul
    className={css(
      Style.base,
      Style.ul,
      (props.color === 'light') && Style.light,
      (props.color === 'dark') && Style.dark,
      (props.color === 'mutedLight') && Style.mutedLight,
      (props.color === 'mutedDark') && Style.mutedDark,
      (props.type === 'ordered') && Style.orderedList,
      (props.type === 'bullet') && Style.bulletList,
      (props.type === 'none') && Style.noneList,
      (props.alignment === 'left') && Style.left,
      (props.alignment === 'right') && Style.right,
      (props.alignment === 'center') && Style.center,
      ...props.style,
    )}
    itemScope={props.itemScope}
    itemType={props.itemType}
  >
    {renderChildren(props.children, props.order, props.size, props.hover, props.noPadding)}
  </ul>
);

List.defaultProps = {
  style: [],
  order: 'vertical',
  color: 'dark',
  size: 'medium',
  alignment: 'left',
  start: 0,
  type: undefined,
  hover: undefined,
  noPadding: false,
  itemScope: undefined,
  itemType: undefined,
};

export default List;
