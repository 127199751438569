/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../style/color';
import { borderRadius, boxShadowUp, zIndexHigh } from '../../style/variables';

export default StyleSheet.create({
  dropdownContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: Theme2.White,
    borderRadius,
    boxShadow: boxShadowUp,
    marginTop: '8px',
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: zIndexHigh,
  },
});
