/* @flow */

import { StyleSheet } from 'aphrodite';
import { Theme2 } from '../../style/color';

export default StyleSheet.create({
  // Global style for app
  appContainer: {
    color: Theme2.Black,
    backgroundColor: Theme2.White,
  },
});
