/**
 * @desc Utility functions for checking, parsing, formatting name string.
 */
import { compact, join, some } from 'lodash';

import { conjunctionList, titleList, forceCaseList, getSuffixList } from './name_const';

interface ParsedName {
  title: string;
  first: string;
  middle: string;
  last: string;
  nick: string;
  suffix: string;
  error: string[];
}
type ParsedNameNoError = Omit<ParsedName, 'error'>;
type ValidNameKeyType = Exclude<keyof ParsedName, 'error'>;

// SwitchNumber: switch, only accept three values
type SwitchNumber = 0 | 1 | -1;
type BooleanNumber = 0 | 1;

// Supported suffix options
export const supportedSuffixOptions = [
  { label: 'Jr', value: 'Jr' },
  { label: 'Sr', value: 'Sr' },
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III ' },
  { label: '-', value: '' },
];

function trimParsedName(pn: ParsedName): ParsedNameNoError {
  const trimmed = {} as ParsedNameNoError;
  const namePartLabels = Object.keys(pn).filter(function (v) {
    return v !== 'error';
  });
  for (let i = 0; i < namePartLabels.length; i += 1) {
    const nk = namePartLabels[i];
    const nv = pn[nk] && pn[nk].trim();
    trimmed[nk] = nv;
  }
  return trimmed;
}

export type NameVariation = {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
};

// Name variation to name
export function nameVariationToName(nameVariation: NameVariation): string {
  return join(
    compact([nameVariation.firstName, nameVariation.middleName, nameVariation.lastName, nameVariation.suffix]),
    ' '
  ).trim();
}

/**
 * parseFullName: Parse full name into parts
 */
export { parseFullName };
function parseFullName(
  nameToParse: string | undefined,
  partToReturn: 'all', // return all name parts, an object
  fixCase?: SwitchNumber, // 0: no,  1:yes, -1: auto
  stopOnError?: BooleanNumber, // 0: no,  1:yes
  useLongLists?: BooleanNumber // 0: no,  1:yes
): ParsedNameNoError;
function parseFullName(
  nameToParse: string | undefined,
  partToReturn: ValidNameKeyType, // return one name part, an string
  fixCase?: SwitchNumber,
  stopOnError?: BooleanNumber,
  useLongLists?: BooleanNumber
): string;
function parseFullName(
  nameToParse: string | undefined,
  partToReturn: ValidNameKeyType | 'all',
  fixCase?: SwitchNumber,
  stopOnError?: BooleanNumber,
  useLongLists?: BooleanNumber
): ParsedNameNoError | string {
  // Check optional params and set default value
  /* eslint-disable no-param-reassign */
  if (typeof fixCase === 'undefined') {
    fixCase = -1;
  }
  if (!stopOnError) {
    stopOnError = 0;
  }
  if (!useLongLists) {
    useLongLists = 0;
  }
  /* eslint-enable no-param-reassign */

  const suffixList = getSuffixList(useLongLists);

  const nameParts: string[] = [];
  const parsedName: ParsedName = {
    title: '',
    first: '',
    middle: '',
    last: '',
    nick: '',
    suffix: '',
    error: [],
  };
  const prefixList: string[] = []; // no use for now

  // If fixCase = 1, fix case of parsedName parts before returning
  function fixParsedNameCase(fixedCaseName: ParsedNameNoError, fixCaseNow: SwitchNumber): ParsedNameNoError {
    let forceCaseListIndex: number;
    let namePartLabels: string[];
    let namePartWords;
    if (fixCaseNow) {
      namePartLabels = Object.keys(parsedName).filter(function (v) {
        return v !== 'error';
      });
      for (let i = 0, l = namePartLabels.length; i < l; i += 1) {
        if (fixedCaseName[namePartLabels[i]]) {
          namePartWords = fixedCaseName[namePartLabels[i]].split(' ');
          for (let j = 0, m = namePartWords.length; j < m; j += 1) {
            forceCaseListIndex = forceCaseList
              .map(function (v) {
                return v.toLowerCase();
              })
              .indexOf(namePartWords[j].toLowerCase());
            if (forceCaseListIndex > -1) {
              // Set case of words in forceCaseList
              namePartWords[j] = forceCaseList[forceCaseListIndex];
            } else if (namePartWords[j].length === 1) {
              // Uppercase initials
              namePartWords[j] = namePartWords[j].toUpperCase();
            } else if (
              namePartWords[j].length > 2 &&
              namePartWords[j].slice(0, 1) === namePartWords[j].slice(0, 1).toUpperCase() &&
              namePartWords[j].slice(1, 2) === namePartWords[j].slice(1, 2).toLowerCase() &&
              namePartWords[j].slice(2) === namePartWords[j].slice(2).toUpperCase()
            ) {
              // Detect McCASE and convert to McCase
              namePartWords[j] = namePartWords[j].slice(0, 3) + namePartWords[j].slice(3).toLowerCase();
            } else if (
              namePartLabels[j] === 'suffix' &&
              nameParts[j].slice(-1) !== '.' &&
              !suffixList.indexOf(nameParts[j].toLowerCase())
            ) {
              // Convert suffix abbreviations to UPPER CASE
              if (namePartWords[j] === namePartWords[j].toLowerCase()) {
                namePartWords[j] = namePartWords[j].toUpperCase();
              }
            } else {
              // Convert to Title Case
              namePartWords[j] = namePartWords[j].slice(0, 1).toUpperCase() + namePartWords[j].slice(1).toLowerCase();
            }
          }
          fixedCaseName[namePartLabels[i]] = namePartWords.join(' '); // eslint-disable-line no-param-reassign
        }
      }
    }
    return fixedCaseName;
  }

  // If stopOnError = 1, throw error, otherwise return error messages in array
  function handleError(errorMessage: string): void {
    if (stopOnError) {
      throw new Error(`Error: ${errorMessage}`);
    } else {
      parsedName.error?.push(`Error: ${errorMessage}`); // eslint-disable-line no-param-reassign
    }
  }

  // final polish the parsed name, remove error property and trim and fix every part
  function finalNameProcess(parsedName: ParsedName, fixCase: SwitchNumber): ParsedNameNoError | string {
    let parsedNameNoErr = trimParsedName(parsedName);
    parsedNameNoErr = fixParsedNameCase(parsedNameNoErr, fixCase);
    return partToReturn === 'all' ? parsedNameNoErr : parsedNameNoErr[partToReturn];
  }

  // If no input name, or input name is not a string, abort
  if (!nameToParse || typeof nameToParse !== 'string') {
    handleError('No input');
    return finalNameProcess(parsedName, fixCase);
  }
  nameToParse = nameToParse.trim(); // eslint-disable-line no-param-reassign

  // Auto-detect fixCase: fix if nameToParse is all upper or all lowercase
  if (fixCase === -1) {
    fixCase = nameToParse === nameToParse.toUpperCase() || nameToParse === nameToParse.toLowerCase() ? 1 : 0; // eslint-disable-line no-param-reassign
  }

  // Nickname: remove and store parts with surrounding punctuation as nicknames
  const regex = /\s(?:[‘’']([^‘’']+)[‘’']|[“”"]([^“”"]+)[“”"]|\[([^\]]+)\]|\(([^\)]+)\)),?\s/g; // eslint-disable-line no-useless-escape
  const partFound = ` ${nameToParse} `.match(regex);
  let partsFound: string[] = [];
  if (partFound) partsFound = partsFound.concat(partFound);
  let partsFoundCount = partsFound.length;
  if (partsFoundCount === 1) {
    parsedName.nick = partsFound[0].slice(2).slice(0, -2);
    if (parsedName.nick.slice(-1) === ',') {
      parsedName.nick = parsedName.nick.slice(0, -1);
    }
    nameToParse = ` ${nameToParse} `.replace(partsFound[0], ' ').trim(); // eslint-disable-line no-param-reassign
    partsFound = [];
  } else if (partsFoundCount > 1) {
    handleError(`${partsFoundCount} nicknames found`);
    for (let i = 0; i < partsFoundCount; i += 1) {
      nameToParse = ` ${nameToParse} `.replace(partsFound[i], ' ').trim(); // eslint-disable-line no-param-reassign
      partsFound[i] = partsFound[i].slice(2).slice(0, -2);
      if (partsFound[i].slice(-1) === ',') {
        partsFound[i] = partsFound[i].slice(0, -1);
      }
    }
    parsedName.nick = partsFound.join(', ');
    partsFound = [];
  }
  if (!nameToParse.trim().length) {
    return finalNameProcess(parsedName, fixCase);
  }

  // Split remaining nameToParse into parts, remove and store preceding commas
  const nameCommas: Array<string | null> = [null];
  for (let i = 0, n = nameToParse.split(' '), l = n.length; i < l; i += 1) {
    let part = n[i];
    let comma: string | null = null;
    if (part.slice(-1) === ',') {
      comma = ',';
      part = part.slice(0, -1);
    }
    nameParts.push(part);
    nameCommas.push(comma);
  }

  // Suffix: remove and store matching parts as suffixes
  for (let l = nameParts.length, i = l - 1; i > 0; i -= 1) {
    const partToCheck =
      nameParts[i].slice(-1) === '.' ? nameParts[i].slice(0, -1).toLowerCase() : nameParts[i].toLowerCase();
    if (
      suffixList.indexOf(partToCheck) > -1 ||
      suffixList.indexOf(`${partToCheck}.`) > -1 ||
      (partsFound.length === 0 && /^\d+$/.test(partToCheck))
    ) {
      partsFound = nameParts.splice(i, 1).concat(partsFound);
      if (nameCommas[i] === ',') {
        // Keep comma, either before or after
        nameCommas.splice(i + 1, 1);
      } else {
        nameCommas.splice(i, 1);
      }
    }
  }
  partsFoundCount = partsFound.length;
  if (partsFoundCount === 1) {
    parsedName.suffix = partsFound[0];
    partsFound = [];
  } else if (partsFoundCount > 1) {
    handleError(`${partsFoundCount} suffixes found`);
    parsedName.suffix = partsFound.join(', ');
    partsFound = [];
  }
  if (!nameParts.length) {
    return finalNameProcess(parsedName, fixCase);
  }

  // Title: remove and store matching parts as titles
  for (let l = nameParts.length, i = l - 1; i >= 0; i -= 1) {
    const partToCheck =
      nameParts[i].slice(-1) === '.' ? nameParts[i].slice(0, -1).toLowerCase() : nameParts[i].toLowerCase();
    if (nameParts.length > 2 && (titleList.indexOf(partToCheck) > -1 || titleList.indexOf(`${partToCheck}.`) > -1)) {
      partsFound = nameParts.splice(i, 1).concat(partsFound);
      if (nameCommas[i] === ',') {
        // Keep comma, either before or after
        nameCommas.splice(i + 1, 1);
      } else {
        nameCommas.splice(i, 1);
      }
    }
  }
  partsFoundCount = partsFound.length;
  if (partsFoundCount === 1) {
    parsedName.title = partsFound[0];
    partsFound = [];
  } else if (partsFoundCount > 1) {
    handleError(`${partsFoundCount} titles found`);
    parsedName.title = partsFound.join(', ');
    partsFound = [];
  }
  if (!nameParts.length) {
    return finalNameProcess(parsedName, fixCase);
  }

  // Join name prefixes to following names
  if (nameParts.length > 1) {
    for (let i = nameParts.length - 2; i >= 0; i -= 1) {
      if (prefixList.indexOf(nameParts[i].toLowerCase()) > -1) {
        nameParts[i] = `${nameParts[i]} ${nameParts[i + 1]}`;
        nameParts.splice(i + 1, 1);
        nameCommas.splice(i + 1, 1);
      }
    }
  }

  // Join conjunctions to surrounding names
  if (nameParts.length > 2) {
    for (let i = nameParts.length - 3; i >= 0; i -= 1) {
      if (conjunctionList.indexOf(nameParts[i + 1].toLowerCase()) > -1) {
        nameParts[i] = `${nameParts[i]} ${nameParts[i + 1]} ${nameParts[i + 2]}`;
        nameParts.splice(i + 1, 2);
        nameCommas.splice(i + 1, 2);
        i -= 1;
      }
    }
  }

  // Suffix: remove and store items after extra commas as suffixes
  nameCommas.pop();
  const firstComma = nameCommas.indexOf(',');
  let remainingCommas = nameCommas.filter(function (v) {
    return v !== null;
  }).length;
  if (firstComma > 1 || remainingCommas > 1) {
    for (let i = nameParts.length - 1; i >= 2; i -= 1) {
      if (nameCommas[i] === ',') {
        partsFound = nameParts.splice(i, 1).concat(partsFound);
        nameCommas.splice(i, 1);
        remainingCommas -= 1;
      } else {
        break;
      }
    }
  }
  if (partsFound.length) {
    if (parsedName.suffix) {
      partsFound = [parsedName.suffix].concat(partsFound);
    }
    parsedName.suffix = partsFound.join(', ');
    partsFound = [];
  }

  // Last name: remove and store last name
  if (remainingCommas > 0) {
    if (remainingCommas > 1) {
      handleError(`${remainingCommas - 1} extra commas found`);
    }
    // Remove and store all parts before first comma as last name
    if (nameCommas.indexOf(',')) {
      parsedName.last = nameParts.splice(0, nameCommas.indexOf(',')).join(' ');
      nameCommas.splice(0, nameCommas.indexOf(','));
    }
  } else {
    // Remove and store last part as last name
    parsedName.last = nameParts.pop() || '';
  }
  if (!nameParts.length) {
    return finalNameProcess(parsedName, fixCase);
  }

  // First name: remove and store first part as first name
  parsedName.first = nameParts.shift() || '';
  if (!nameParts.length) {
    return finalNameProcess(parsedName, fixCase);
  }

  // Middle name: store all remaining parts as middle name
  if (nameParts.length > 2) {
    handleError(`${nameParts.length} middle names`);
  }
  parsedName.middle = nameParts.join(' ');

  return finalNameProcess(parsedName, fixCase);
}

/* eslint-enable */

export const getFirstName = (fullName: any): string => {
  if (!fullName) {
    return '';
  }
  return parseFullName(String(fullName), 'first', -1, 0, 1);
};

/**
 * Returns a single name (e.g. John from John Smith).
 * If only a single name is passed in (e.g. John), returns John (getFirstName returns an empty string in this case).
 * @param name
 */
export const getSingleName = (name: string): string => {
  const nameObj = parseFullName(name, 'all');
  return nameObj.first ? nameObj.first : nameObj.last;
};

/**
 * Format string into name.
 * - Trims spaces in front/end, removes multiple space in the middle.
 * - Accept accented chars, eg; é,å, etc.
 * - Accept hyphenated names, eg; "John-Smith".
 * - Capitalization:
 *   - Each separate name string starts with a capital letter
 *     - JOHN DOE -> John Doe
 *     - john doe -> John Doe
 *   - Strings following a hyphen should be defaulted to capitalized
 *     - Jones-Smith
 *   - Allow a user to edit & save their last name with the first character following a "Mac" or "Mc"
 *     capitalized (no defaulting or reformatting)
 *     - Example: McAllister or McCallum
 *       - An all caps or all lowercase "Mc-" name will not have any special formatting, but users will
 *         likely notice when their own surname isn't formatted correctly and should be allowed to save
 *         with the correct consonant capitalized
 */
export const normalizeName = (name: string): string =>
  name
    // We found some carrier using \u00A0 (non-breaking space) as space. We should convert it to space.
    .replace(/[\u00A0]/g, ' ')
    .replace(/[^a-zA-Z0-9\u00C0-\u017F- ]/g, '')
    .replace(/\s\s+/g, ' ')
    .trim()
    .split(' ')
    .map((namePart, partIndex, parts) => {
      return Array.from(namePart)
        .map((char, charIndex) => {
          // Capitalize first letter
          if (charIndex === 0) {
            return char.toUpperCase();
          }
          // If it's last name, don't modify the letter after "Mac" or "Mc"
          if (
            partIndex === parts.length - 1 &&
            ((charIndex === 2 && namePart.slice(0, 2) === 'Mc') || (charIndex === 3 && namePart.slice(0, 3) === 'Mac'))
          ) {
            return char;
          }
          // Capitalize the letter after a hyphen
          if (charIndex > 0 && namePart[charIndex - 1] === '-') {
            return char.toUpperCase();
          }
          // Lowercase all other letters
          return char.toLowerCase();
        })
        .join('');
    })
    .join(' ');

/**
 * Check if a full name is a match regardless of
 * their name and surname order or punctuation charters
 * @param {String} name Full name to compare
 * @param {String} searchName Full name which is compared
 * @example
 * isMatchFullName('John Smith', 'John Smith') => true;
 * isMatchFullName('John Smith', 'Smith John') => true;
 * isMatchFullName('Smith, John', 'John, Smith') => true;
 * isMatchFullName('Smith John', 'John Smith Scott') => false;
 */
export const isMatchFullName = (name: string, searchName: string): boolean => {
  if (name && searchName) {
    const filteredNameArr = name.toLowerCase().match(/[^_\W]+/g);
    const filteredSearchNameArr = searchName.toLowerCase().match(/[^_\W]+/g);
    if (filteredNameArr && filteredNameArr.length && filteredSearchNameArr && filteredSearchNameArr.length) {
      if (filteredSearchNameArr.length <= 1) return false;
      return !some(filteredSearchNameArr, (elem, i) => filteredNameArr.indexOf(filteredSearchNameArr[i]) === -1);
    }
    return false;
  }
  return false;
};

/**
 * Remove accents in name
 * For example, José -> Jose
 */
export const removeAccentsInName = (name: string) => name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
