/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import LinkStyle from './style';

type Props = {
  id?: string,
  href?: string,
  target?: string,
  style?: Array<any>,
  display?: 'inline' | 'block'|'sameline',
  onClick?: () => void,
  children: any,
  inheritColor?: boolean,
  underline?: boolean,
  itemProp?: string,
  className?: string,
  fullWidth?: boolean,
};

const Link = (props: Props) => (
  <a
    id={props.id && props.id}
    href={props.href}
    className={css(
      LinkStyle.base,
      props.fullWidth && LinkStyle.fullWidth,
      props.inheritColor && LinkStyle.inheritColor,
      props.underline && LinkStyle.underline,
      (props.display === 'inline') && LinkStyle.inline,
      (props.display === 'block') && LinkStyle.block,
      (props.display === 'sameline') && LinkStyle.sameline,
      ...props.style,
    ) + (props.className ? ` ${props.className}` : '')}
    onClick={props.onClick}
    target={props.target}
    rel={props.target ? 'noopener noreferrer' : ''}
    itemProp={props.itemProp}
  >
    {props.children}
  </a>
);

Link.defaultProps = {
  href: undefined,
  style: [],
  onClick: undefined,
  inheritColor: undefined,
  target: undefined,
  display: 'inline',
  underline: false,
  itemProp: undefined,
  fullWidth: false,
};

export default Link;
