/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  children: any,
  style?: Array<any>,
  itemProp?: string,
};

/**
 * H1
 */
const H1 = (props: Props) => (
  <h1
    className={css(Style.base, Style.h1, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h1>
);

H1.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * H2
 */
const H2 = (props: Props) => (
  <h2
    className={css(Style.base, Style.h2, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h2>
);

H2.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * H3
 */
const H3 = (props: Props) => (
  <h3
    className={css(Style.base, Style.h3, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h3>
);

H3.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * H4
 */
const H4 = (props: Props) => (
  <h4
    className={css(Style.base, Style.h4, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h4>
);

H4.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * H5
 */
const H5 = (props: Props) => (
  <h5
    className={css(Style.base, Style.h5, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h5>
);

H5.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * H6
 */
const H6 = (props: Props) => (
  <h6
    className={css(Style.base, Style.h6, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </h6>
);

H6.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * Navigation Header
 */
const NavigationHeader = (props: Props) => (
  <div
    className={css(Style.base, Style.navigationHeader, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

NavigationHeader.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * P
 */
const ParagraphDefault = (props: Props) => (
  <div
    className={css(Style.base, Style.paragraphDefault, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

ParagraphDefault.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * Paragraph Small
 */
const ParagraphSmall = (props: Props) => (
  <div
    className={css(Style.base, Style.paragraphSmall, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

ParagraphSmall.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * Paragraph Extra Small
 */
const ParagraphExtraSmall = (props: Props) => (
  <div
    className={css(Style.base, Style.paragraphExtraSmall, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

ParagraphExtraSmall.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * Paragraph Large
 */
const ParagraphLarge = (props: Props) => (
  <div
    className={css(Style.base, Style.paragraphLarge, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

ParagraphLarge.defaultProps = {
  style: [],
  itemProp: undefined,
};

/**
 * Signature
 */
const Signature = (props: Props) => (
  <div
    className={css(Style.base, Style.signature, ...props.style)}
    itemProp={props.itemProp}
  >
    {props.children}
  </div>
);

Signature.defaultProps = {
  style: [],
  itemProp: undefined,
};

// Export all the headers here
export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  NavigationHeader,
  ParagraphDefault,
  ParagraphLarge,
  ParagraphSmall,
  ParagraphExtraSmall,
  Signature,
};
