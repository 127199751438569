/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../style/color';
import { fontFamilyRegular, borderColor } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  base: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    fontSize: '16px',
  },
  ul: {
    listStyle: 'none',
  },
  light: {
    color: Theme2.White,
  },
  mutedLight: {
    color: Theme2.White60,
  },
  dark: {
    color: Theme2.Black,
  },
  mutedDark: {
    color: Theme2.GrayXd60,
  },
  vertical: {
    padding: '8px 0',
  },
  horizontal: {
    display: 'inline-block',
    padding: '0 8px',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
  textNoWrap: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  sizeSmall: {
    fontSize: '12px',
  },
  sizeMedium: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  sizeLarge: {
    fontSize: '16px',
  },
  sizeXLarge: {
    fontSize: '20px',
  },
  bulletList: {
    listStyle: 'circle',
    paddingLeft: '22px',
  },
  orderedList: {
    listStyle: 'decimal',
    paddingLeft: '22px',
  },
  noneList: {
    listStyle: 'none',
    paddingLeft: '0',
  },
  noPadding: {
    padding: '0',
  },
  hoverLight: {
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '4px',
    ':hover': {
      backgroundColor: Theme2.White20,
    },
  },
  hoverDark: {
    padding: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme2.GrayXl,
    },
  },
  dropdownListItem: {
    padding: '16px',
    borderBottom: `1px solid ${borderColor}`,
    ':hover': {
      backgroundColor: Theme2.GrayXl,
    },
  },
});
