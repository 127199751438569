import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import get from 'lodash/get';
import { init } from '@sentry/browser';

import App from './containers/app/App';

/**
 * Init sentry for bug tracing
 */
if (window.Jerry.sentryDSN) {
  init({
    dsn: window.Jerry.sentryDSN,
    environment: window.Jerry.env,
  });
}

/**
 * The Apollo GraphQL client
 */
const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
  headers: {
    Accept: 'application/json',
    'GraphQL-Client-Name': get(window, 'Jerry.clientName', 'Not set'),
  },
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
  },
});

/**
 * Render App component inside the React Router
 */
const elm = document.getElementById('app');
if (elm == null) throw new Error('No element found');
ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App redirectURL={window.Jerry.reactProps.redirectURL} />
    </ApolloProvider>
  </Router>,
  elm
);
