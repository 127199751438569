/* @flow */

import { StyleSheet } from 'aphrodite';
import { Theme2 } from '../../style/color';

import { sm, lg, md, fontFamilyMedium, fontFamilyRegular, fontSignature } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  base: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
  },
  h1: {
    fontSize: '60px',
    lineHeight: '68px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '0.66em',
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '48px',
      lineHeight: '48px',
      marginBottom: '0.83em',
    },
    [`@media (max-width: ${md}px)`]: {
      fontSize: '32px',
      lineHeight: '38px',
      marginBottom: '0.83em',
    },
  },
  h2: {
    fontSize: '48px',
    lineHeight: '48px',
    fontFamily: fontFamilyMedium,
    fontWeight: '500',
    marginBottom: '0.83em',
    [`@media (max-width: ${md}px)`]: {
      fontSize: '28px',
      lineHeight: '32px',
      marginBottom: '0.83em',
    },
  },
  h3: {
    fontSize: '36px',
    lineHeight: '48px',
    fontFamily: fontFamilyRegular,
    fontWeight: '500',
    marginBottom: '1em',
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '1.33em',
    },
  },
  h4: {
    fontSize: '24px',
    lineHeight: '36px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '1.33em',
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '18px',
      lineHeight: '24px',
      marginBottom: '1.67em',
    },
  },
  h5: {
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: fontFamilyMedium,
    fontWeight: '500',
    marginBottom: '1.67em',
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '1.84em',
    },
  },
  h6: {
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '2.33em',
  },
  navigationHeader: {
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: fontFamilyRegular,
    fontWeight: '450',
    padding: '0 8px',
    [`@media (max-width: ${md}px)`]: {
      marginRight: '0',
    },
  },
  paragraphDefault: {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '1.84em',
    [`@media (max-width: ${lg}px)`]: {
      marginBottom: '1em',
    },
  },
  paragraphSmall: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '2em',
  },
  paragraphExtraSmall: {
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '0.5em',
  },
  paragraphLarge: {
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    marginBottom: '1.5em',
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '1.84em',
    },
  },
  signature: {
    fontSize: '50px',
    fontFamily: fontSignature,
    [`@media (max-width: ${lg}px)`]: {
      fontSize: '40px',
    },
  },
  autoLineHeight: {
    lineHeight: 'auto',
  },
  textCenter: {
    textAlign: 'center',
  },
  toUppercase: {
    textTransform: 'uppercase',
  },
  toCapitalize: {
    textTransform: 'capitalize',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  underline: {
    textDecoration: 'underline',
  },
  noDecoration: {
    textDecoration: 'none',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  noMarginBottom: {
    marginBottom: '0',
    [`@media (max-width: ${lg}px)`]: {
      marginBottom: '0',
    },
  },
  noMarginTop: {
    marginTop: '0',
    [`@media (max-width: ${lg}px)`]: {
      marginTop: '0',
    },
  },
  textBold: {
    fontFamily: fontFamilyMedium,
    fontWeight: '500',
  },

  inline: {
    display: 'inline-block',
  },

  colorBlack: {
    color: Theme2.Black,
  },
  colorBlackMuted: {
    color: Theme2.GrayXd,
  },
  colorBlackExtraMuted: {
    color: Theme2.GrayD,
  },
  colorWhite: {
    color: Theme2.White,
  },
  colorWhiteMuted: {
    color: Theme2.White80,
  },
  colorPrimary: {
    color: Theme2.PrimaryXd,
  },
  colorGray: {
    color: Theme2.GrayXd,
  },
  opacity60: {
    opacity: 0.6,
  },
  opacity50: {
    opacity: 0.5,
  },
  clickable: {
    cursor: 'pointer',
  },
  headerLoadingText: {
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '8px',
    marginTop: '20px',
    [`@media (max-width: ${md}px)`]: {
      fontSize: '24px',
      lineHeight: '28px',
      marginBottom: '8px',
      marginTop: '20px',
    },
  },
  responsiveLeft: {
    textAlign: 'center',
    [`@media (max-width: ${sm}px)`]: {
      textAlign: 'left',
    },
  },
  letterSpacing1: {
    letterSpacing: '1px',
  },
});
