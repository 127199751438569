/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../../../../../style/color';
import { md } from '../../../../../../style/variables';

const base = {
  display: 'flex',
  backgroundColor: Theme2.White,
  width: '100%',
  height: '80px',
  alignItems: 'center',
  position: 'relative',
  [`@media (max-width: ${md}px)`]: {
    height: '62px',
  },
};

const progressBarContainer = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
};
const progressBar = {
  height: '4px',
  backgroundColor: Theme2.PrimaryXd,
  position: 'relative',
  transition: 'width 1s',
};

const progressBarIndicator = {
  height: '18px',
  width: '30px',
  backgroundColor: Theme2.PrimaryXd,
  position: 'absolute',
  right: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '0',
  borderRadius: '0 0 4px 0',
};

export default StyleSheet.create({
  base,
  progressBar,
  progressBarIndicator,
  progressBarContainer,
});
