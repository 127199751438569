/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../../../../../style/color';
import { md } from '../../../../../../style/variables';

const base = {
  display: 'flex',
  backgroundColor: Theme2.White,
  width: '100%',
  minHeight: '80px',
  padding: '16px 0',
  alignItems: 'center',
  borderTop: '1px solid #DBDDDE',
  position: 'relative',
};

const trustLogo = {
  width: '100%',
  marginRight: '8px',
};

const trustLogoContainer = {
  maxWidth: '125px',
};

const footerContent = {
  display: 'flex',
  justifyContent: 'flext-start',
  [`@media (max-width: ${md}px)`]: {
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const footerContentDisclaimer = {
  [`@media (max-width: ${md}px)`]: {
    margin: '16px 0',
  },
};

const progressBarContainer = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
};
const progressBar = {
  height: '4px',
  width: '33%',
  backgroundColor: Theme2.PrimaryXd,
  position: 'relative',
};

const progressBarIndicator = {
  height: '18px',
  width: '30px',
  backgroundColor: Theme2.PrimaryXd,
  position: 'absolute',
  right: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '0',
  borderRadius: '0 0 4px 0',
};

export default StyleSheet.create({
  base,
  trustLogo,
  trustLogoContainer,
  progressBar,
  progressBarIndicator,
  progressBarContainer,
  footerContent,
  footerContentDisclaimer,
});
