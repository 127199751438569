/* @flow */

import React from 'react';
import { observer } from 'mobx-react';
import { css } from 'aphrodite';

import Style from '../../style';

import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';

import Login from '../login/Login';

/**
 * Login screen
 */
@observer
class App extends React.Component<any, any> {
  componentWillMount() {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.remove();
    }
  }

  /* Render */
  render() {
    return (
      <div className={css(Style.appContainer)}>
        {/* Nav header */}
        <Navigation />

        {/* Body */}
        <Login {...this.props} />

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default App;
