/* @flow */

import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { css } from 'aphrodite';

import Logo from '../../../../../../components/logo/Logo';
import Link from '../../../../../../components/link/Link';
import Typography from '../../../../../../components/typography/Typography';
import TypographyStyle from '../../../../../../components/typography/style';
import List from '../../../../../../components/list/List';
import ListItem from '../../../../../../components/list/ListItem';

import NavigationStyle from './style';
import Flex from '../../../../../../style/flex';
import Responsive from '../../../../../../style/responsive';

/**
 * Navigation
 */
@observer
class Navigation extends React.Component<any, any> {
  @observable showFAQModal = false;
  @observable showHelpModal = false;

  render() {
    return (
      <div>
        {/* Top bar */}
        <div className={css(NavigationStyle.base, NavigationStyle.topNavigationBar)}>
          <div className="container-fluid">
            <div className="row align-items-center">

              {/* Logo */}
              <div className="col-2">
                <Link href="/">
                  <Logo responsiveMobile />
                </Link>
              </div>

              {/* Links */}
              <div className="col-10">
                <div className={css(Responsive.hiddenMd)}>
                  <List
                    order="horizontal"
                    alignment="right"
                    style={[Flex.base, Flex.alignItemsCenter, Flex.justifyContentEnd]}
                  >
                    <ListItem>Help? (833) 445-3779</ListItem>
                  </List>
                </div>

                {/* Links -- mobile view */}
                <div className={css(Responsive.visibleMd)}>
                  <div className={css(Flex.base, Flex.justifyContentSpaceBetween)}>
                    {/* Support phone number */}
                    <Typography.ParagraphSmall
                      style={[
                        TypographyStyle.colorBlack,
                        TypographyStyle.textLeft,
                        TypographyStyle.noMarginBottom,
                        TypographyStyle.inline,
                      ]}
                    >
                      (833) 445-3779
                    </Typography.ParagraphSmall>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Navigation;
