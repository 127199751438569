/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Style from './style';

type Props = {
  id?: string,
  children: any,
  style?: Array<any>,
  onClick?: any,
  itemScope?: boolean,
  itemType?: string,
  itemProp?: string,
};

const ListItem = (props: Props) => (
  <li
    id={props.id}
    className={css(
      Style.li,
      ...props.style,
    )}
    onClick={props.onClick}
    itemScope={props.itemScope}
    itemType={props.itemType}
    itemProp={props.itemProp}
  >
    {props.children}
  </li>
);

ListItem.defaultProps = {
  style: [],
  onClick: undefined,
  itemScope: undefined,
  itemType: undefined,
  itemProp: undefined,
};

export default ListItem;
