/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import Typography from '../../../../../../components/typography/Typography';
import TypographyStyle from '../../../../../../components/typography/style';
import Link from '../../../../../../components/link/Link';
import Spacing from '../../../../../../style/spacing';
import Flex from '../../../../../../style/flex';

import FooterStyle from './style';
import BBBBadge from './imgs/bbb-logo.png';

/**
 * Footer
 */
class Footer extends React.Component<{}> {
  sealElm: any = null;

  componentDidMount() {
    if (this.sealElm) {
      const script = document.createElement('script');
      script.src =
        'https://seal.websecurity.norton.com/getseal?host_name=getjerry.com&amp;size=M&amp;use_flash=NO&amp;use_transparent=YES&amp;lang=en';
      script.async = true;

      this.sealElm.appendChild(script);
    }
  }

  render() {
    return (
      <div className={css(FooterStyle.base, FooterStyle.bottomFooterBar)}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className={css(FooterStyle.footerContent)}>
                <div className={css(Spacing.mr16, Spacing.ml16, FooterStyle.footerContentDisclaimer)}>
                  <Typography.H6 style={[TypographyStyle.colorBlackMuted, TypographyStyle.noMarginBottom]}>
                    Jerry Insurance Agency is a{' '}
                    <Link href="/licenses" inheritColor underline>
                      licensed
                    </Link>{' '}
                    insurance agency in 50 states.
                  </Typography.H6>
                  <Typography.H6 style={[TypographyStyle.colorBlackMuted, TypographyStyle.noMarginBottom]}>
                    Copyrights © Jerry 2020. All rights reserved.
                  </Typography.H6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={`align-items-end justify-content-end
                ${css(Flex.base)}`}
              >
                <div className={css(FooterStyle.trustLogoContainer)}>
                  <img src={BBBBadge} alt="BBB Badge" className={css(FooterStyle.trustLogo)} />
                </div>
                <div className={css(FooterStyle.trustLogoContainer)}>
                  <table
                    width="115"
                    cellPadding="2"
                    cellSpacing="0"
                    title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications."
                  >
                    <tbody>
                      <tr>
                        <td
                          width="115"
                          ref={(c) => {
                            this.sealElm = c;
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
