import { times, isNumber, map, split, toInteger, floor, compact, replace } from 'lodash';
import moment from 'moment';

import { zFill } from './text';
import { VIN_LENGTH } from './vin';

/**
 * Split string like String.split but not much than count pieces
 * array will contain first n splits + rest part
 * split('a,b,c', ',', 2) => ['a', 'b,c']
 */
export const splitRest = (text: string, splitter: string, count: number): string[] => {
  const s = text.split(splitter);
  const first = s.slice(0, count - 1);
  const rest = s.slice(count - 1).join(splitter);
  return rest ? first.concat(rest) : first;
};

/**
 * Return currency value in CENTS supposing US input format (. - cents delimiter)
 */

export const parseUSDollarsValue = (value?: string | number | null | boolean): number => {
  // undefined, null and all other false-casting values will be taken as 0
  const cleanValue = `${value || 0}`.replace(/[^0-9.-]/g, '');

  // Check dots
  const dotsCount = (cleanValue.match(/\./g) || []).length;
  if (dotsCount > 1 || (dotsCount === 1 && cleanValue.length === 1)) {
    // more than one dot or '.'
    throw new Error(`Invalid numeric value: ${value} (cleaned: ${cleanValue})`);
  }

  // Check dashes
  const dashesOrMinusesCount = (cleanValue.match(/-/g) || []).length;
  if (dashesOrMinusesCount > 1 || cleanValue.search(/-/g) > 0) {
    // Many dashes or dash not at the beginning
    throw new Error(`Invalid numeric value: ${value} (cleaned: ${cleanValue})`);
  }

  const [integerPart, fractionalPart] = cleanValue.split('.');
  //                      '' is OK              Add zeroes or truncate to 2
  const centsString = (integerPart || '') + zFill(fractionalPart || '', 2, '0', true);
  return parseInt(centsString, 10);
};

/**
 * Return currency value in DOLLARS
 */

export const parseUSCentsValue = (val: number): number => floor(val / 100, 2);

/**
 * Return monthly premium based on annual premium and terms as 12
 */

export const convertToMontlyPremium = (val: number) => floor(val / 12, 2);

/**
 * Return annual premium based on monthly premium and terms as 12
 */

export const convertToAnnualPremium = (val: number) => floor(val * 12, 2);

/**
 * Phone number formatting
 */
export const formatPhone = (num: string, addParentheses = true): string => {
  const p1 = num.slice(0, 3);
  const p2 = num.slice(3, 6);
  const p3 = num.slice(6, 10);

  if (p1.length < 1) {
    return '';
  }
  if (p1.length < 3 || p2.length < 1) {
    return `(${p1}`;
  }
  if (p2.length < 3 || p3.length < 1) {
    return `(${p1}) ${p2}`;
  }

  return addParentheses ? `(${p1}) ${p2} ${p3}` : `${p1} ${p2} ${p3}`;
};

// we should keep the last 10 number
export const unformatPhone = (num: string): string => num.replace(/[^0-9]/g, '').substr(-10);

/**
 * SSN formatting
 */
export const formatSSN = (num: string): string => {
  const p1 = num.slice(0, 3);
  const p2 = num.slice(3, 5);
  const p3 = num.slice(5, 9);

  if (p1.length < 1) {
    return '';
  }
  if (p1.length < 3 || p2.length < 1) {
    return `${p1}`;
  }
  if (p2.length < 2 || p3.length < 1) {
    return `${p1}-${p2}`;
  }

  return `${p1}-${p2}-${p3}`;
};

export const unformatSSN = (num: string): string => {
  const ssn = num.replace(/[^0-9]/g, '').substr(0, 9);
  return ssn;
};

export const formatSSNT = (ssn?: string) => ssn && formatSSN(ssn);
export const unformatSSNT = (ssn?: string) => ssn && unformatSSN(ssn);

export const formatYear = (year: string) => year && year.replace(/[^0-9]/gi, '').substr(0, 4);

/**
 * Date formatting.
 * Supported formats: YYYY-MM-DD, YYYY/MM/DD, MM-DD-YYYY, MM/DD/YY, MM/DD/YYYY
 */
export const formatDate: (date: string, format?: string) => string = (date, format = 'YYYY-MM-DD'): string => {
  let divider = '-';
  switch (format) {
    case 'YYYY-MM-DD':
    case 'MM-DD-YYYY':
    case 'MM-DD-YY':
    case 'YYYY-MM':
      divider = '-';
      break;
    case 'YYYY/MM/DD':
    case 'MM/DD/YYYY':
    case 'MM/DD/YY':
    case 'YYYY/MM':
    case 'MM/YYYY':
      divider = '/';
      break;
    default:
      throw new Error(`Date format ${format} is unsupported`);
  }
  switch (format) {
    case 'YYYY-MM-DD':
    case 'YYYY/MM/DD': {
      const p1 = date.slice(0, 4);
      const p2 = date.slice(4, 6);
      const p3 = date.slice(6, 8);

      if (p1.length < 1) {
        return '';
      }
      if (p1.length < 4 || p2.length < 1) {
        return `${p1}`;
      }
      if (p2.length < 2 || p3.length < 1) {
        return `${p1}${divider}${p2}`;
      }

      return `${p1}${divider}${p2}${divider}${p3}`;
    }
    case 'YYYY-MM':
    case 'YYYY/MM': {
      const p1 = date.slice(0, 4);
      const p2 = date.slice(4, 6);

      if (p1.length < 1) {
        return '';
      }
      if (p1.length < 4 || p2.length < 1) {
        return `${p1}`;
      }

      return `${p1}${divider}${p2}`;
    }
    case 'MM/YYYY': {
      const p1 = date.slice(0, 2);
      const p2 = date.slice(2, 6);

      if (p1.length < 1) {
        return '';
      }
      if (p1.length < 2 || p2.length < 4) {
        return `${p1}`;
      }

      return `${p1}${divider}${p2}`;
    }
    case 'MM-DD-YYYY':
    case 'MM/DD/YYYY': {
      const p1 = date.slice(0, 2);
      const p2 = date.slice(2, 4);
      const p3 = date.slice(4, 8);

      if (p1.length < 1) {
        return '';
      }
      if (p1.length < 2 || p2.length < 1) {
        return `${p1}`;
      }
      if (p2.length < 2 || p3.length < 1) {
        return `${p1}${divider}${p2}`;
      }

      return `${p1}${divider}${p2}${divider}${p3}`;
    }
    case 'MM/DD/YY':
    case 'MM-DD-YY': {
      const p1 = date.slice(0, 2);
      const p2 = date.slice(2, 4);
      const p3 = date.slice(4, 6);

      if (p1.length < 1) {
        return '';
      }
      if (p1.length < 2 || p2.length < 1) {
        return `${p1}`;
      }
      if (p2.length < 2 || p3.length < 1) {
        return `${p1}${divider}${p2}`;
      }

      return `${p1}${divider}${p2}${divider}${p3}`;
    }
    default:
      return '';
  }
};

export const convertDate = (date: string, formatFrom: string, formatTo: string): string => {
  if (!date || !moment(date, formatFrom, true).isValid()) {
    return '';
  }
  return moment(date, formatFrom, true).format(formatTo);
};

/**
 * Date unformatting.
 * Removes delimiters from provided date of provided format
 */
export const unformatDate: (date: string, format?: string) => string = (date, format = 'YYYY-MM-DD'): string => {
  let length = 8;
  switch (format) {
    case 'YYYY-MM-DD':
    case 'MM-DD-YYYY':
    case 'YYYY/MM/DD':
    case 'MM/DD/YYYY':
      length = 8;
      break;
    case 'MM-DD-YY':
    case 'MM/DD/YY':
      length = 6;
      break;
    case 'YYYY-MM':
    case 'YYYY/MM':
    case 'MM/YYYY':
      length = 6;
      break;
    default:
      throw new Error(`Date format ${format} is unsupported`);
  }
  return date.replace(/[^0-9]/g, '').substr(0, length);
};

type DateInputType = Date | string;

const toLocal = (date: DateInputType | moment.Moment): DateInputType | moment.Moment =>
  moment(date).isValid() ? moment.utc(date).local().toDate() : date;

const gracefulDateTimeFormat: (
  date: DateInputType | moment.Moment,
  format: string,
  defaultValue?: string | null
) => string | null = (date: DateInputType | moment.Moment, format, defaultValue = null) => {
  const md = moment(date);
  if (md.isValid()) return md.format(format);
  return typeof date === 'string' ? date : defaultValue;
};

export const DATE_FORMAT_US = 'MM/DD/YYYY';
export const DATE_FORMAT_US_TECH = 'MM-DD-YYYY';
// Dates format in ISU statements document
export const DATE_FORMAT_US_SHORT = 'M/D/YY';
export const DATE_TIME_FORMAT_US = 'MM/DD/YY h:mmA';
export const DATE_TIME_FORMAT_SECONDS_US = 'MM/DD/YY h:mm:ssa';
export const DATE_FORMAT_TECH = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_TECH = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FORMAT_YEAR = 'YYYY';
export const TIME_FORMAT_TECH = 'HH:mm:ss';

export const formatDateTime = (date: DateInputType | moment.Moment): string => moment(date).format('MM-DD-YYYY h:mmA');

export const formatTechDateTime = (date: DateInputType | moment.Moment): string =>
  moment(date).format('YYYY-MM-DD hh:mm:ss');

export const formatTechDate = (date: DateInputType | moment.Moment): string => moment(date).format(DATE_FORMAT_TECH);

export const formatDateHumanUS = (date: DateInputType | moment.Moment) => gracefulDateTimeFormat(date, 'MM/DD/YY');

export const formatDateHumanExtUS: (
  date: DateInputType | moment.Moment,
  defaultValue?: string | null
) => string | null = (date, defaultValue = null) => gracefulDateTimeFormat(date, DATE_FORMAT_US, defaultValue);

export const formatDateTechUS: (date: DateInputType | moment.Moment, defaultValue?: string | null) => string | null = (
  date,
  defaultValue = null
) => gracefulDateTimeFormat(date, DATE_FORMAT_US_TECH, defaultValue);

export const formatDateTimeHumanUS = (date: DateInputType | moment.Moment) =>
  gracefulDateTimeFormat(toLocal(date), DATE_TIME_FORMAT_US);

export const formatDateTimeHumanSecondsUS = (date: DateInputType | moment.Moment) =>
  gracefulDateTimeFormat(toLocal(date), DATE_TIME_FORMAT_SECONDS_US);

// this function is deprecated and should go after BSSearch model removed in favor of VendorResponse
export const formatDateZeroPad: (date: string) => string | null = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-M-D').format('YYYY-MM-DD');
};

/**
 * ZIP formatting
 */
export const formatZIP = (num: string): string => num;
export const unformatZIP = (num: string): string => num.replace(/[^0-9]/g, '').substr(0, 5);
export const unformatZIPDefaultNull = (num: string): string | null =>
  replace(num, /[^0-9]/g, '') ? replace(num, /[^0-9]/g, '').substr(0, 5) : null;

/**
 * Format number
 */
export const formatNum = (num: string | number): string => (!isNaN(+num) ? `${num}` : '');
export const unformatNum = (num: string): number => parseInt(num.replace(/[^0-9]/g, ''), 10);
export const unformatFloat = (num: string): number => parseFloat(num.replace(/[^\d.-]/g, ''));

/**
 * Formats float to two decimal places
 */
export const formatFloat = (num: string | number): string => {
  const val = `${num}`.match(/^\d+(\.\d?\d?)?/);
  return (val && val[0]) || '';
};

const commaNumRegEx = /^(-)?(\d+)(\..*)?$/;
/**
 * Comma decorate number
 * Supporting signed numbers with dot and suffix after it, like -5000.05%
 */
export const formatCommaNum = (num: string | number): string => {
  const str = `${num}`;
  const [sign = '', int, rest = ''] = (str.match(commaNumRegEx) || []).slice(1);
  if (!int) {
    return str;
  }
  const strLen = int.length;
  const blocks = Math.ceil(strLen / 3);
  let newStr = '';
  times(blocks, (i) => {
    let idx = strLen - (i + 1) * 3;
    let count = 3;
    if (idx < 0) {
      count = 3 + idx;
      idx = 0;
    }
    if (newStr === '') {
      newStr = int.substr(idx, count);
    } else {
      newStr = `${int.substr(idx, count)},${newStr}`;
    }
  });
  return `${sign}${newStr}${rest}`;
};

/**
 * Format currency
 */
export const formatCurrency = (num: string | number): string => formatFloat(num);

/*
 * Format money (option of showing 2 decimals)
 */
export const formatMoney = (amount: number, twoDecimal = false): string => {
  const number = formatCommaNum(
    `${(twoDecimal && amount !== Math.round(amount) ? amount?.toFixed(2) : amount) || '0'}`
  );
  const formatted = `${number}`.match(/^-?[,\d]+(\.\d?\d?)?/);
  const money = (formatted && formatted[0]) || '';
  if (money.startsWith('-')) {
    return `-$${money.substr(1)}`;
  }
  return `$${money}`;
};

/**
 * Helper to default to '–' placeholder instead of $0
 */
export const formatMoneyOrDefault = (amount?: number, twoDecimal = false): string =>
  amount ? formatMoney(amount, twoDecimal) || '–' : '–';

/**
 * Helper to default to amount per month
 * @param {number} amount of premium value
 * @param {number} totalMonth the total monthly value at which it is calculated
 * @returns {string} String the premium amount per month
 */
export const formatMoneyPerMonthOrDefault = (amount: number, totalMonth: number) =>
  amount && totalMonth ? `(${formatMoney(amount / totalMonth)}/mo)` : null;

/**
 * Cut a text for a limited number of chars (limit) and attach "..."
 */
export const shortenText: (title: string, limit?: number) => string = (title, limit = 50) =>
  (title.length > limit && `${title.substr(0, limit)}...`) || title;

/**
 * Get the file name form S3 path url
 */
export const getFileNameFromS3url = (key: string): string => split(key, '/').pop() as string;

/**
 * Get the UserID that uploaded the file from the path S3 url
 */
export const getUploadedByIdFromS3url = (key: string): number => toInteger(split(key, '/').shift());

/**
 * Display file size in a readable way
 */
export const formatFileSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!isNumber(bytes) || bytes < 0) {
    return 'n/a';
  }
  if (bytes === 0) {
    return `${bytes} ${sizes[0]}`;
  }
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

/**
 * Format name by capitalizing the first character
 */
export const formatName = (name?: string): string => {
  if (!name) return '';
  const nameArr = name.toLocaleLowerCase().split(' ');
  return map(nameArr, (p) => `${(p[0] || '').toLocaleUpperCase()}${p.substr(1)}`).join(' ');
};

type AddressInputType = {
  street?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
};

/**
 * Format address object to readable format
 */
export const formatAddress = (address: AddressInputType): string => {
  const arr = compact([address.street, address.line2, address.city, address.state]);
  return arr.join(', ');
};

const AMEX_DIGIT_COUNT = 15;
const AMEX_FIRST_DIGIT = '3';

const isAMEX = (creditCardNumber: string): boolean =>
  creditCardNumber.startsWith(AMEX_FIRST_DIGIT) && creditCardNumber.length === AMEX_DIGIT_COUNT;

/**
 * Helper to get a string with just the last 4 digits of a credit card shown
 * This uses the tokenized string from VGS, which should be 19 digits and retains the last 4 digits
 * @param {*} cardNumber The tokenized card number with last 4 digits still accurate
 */
const obfuscateCardNumber = (creditCardNumber: string): string => `XXXX-XXXX-XXXX-${creditCardNumber.substr(-4)}`;

/**
 * Format credit card number:
 *
 * AMEX: XXXX-XXXXXX-XXXXX
 * VISA, etc: XXXX-XXXX-XXXX-XXXX
 *
 * @param {String} creditCardNumber
 */
export const formatCreditCard = (creditCardNumber: string, isRevealed = true): string => {
  if (!isRevealed) return obfuscateCardNumber(creditCardNumber);

  // just in case strip all the non-digits
  const cc = creditCardNumber.replace(/\D/g, '');
  if (isAMEX(cc)) {
    return `${cc.slice(0, 4)}-${cc.slice(4, 10)}-${cc.slice(10)}`;
  }
  return replace(cc, /(\d{1,4}(?!\s))/g, '$1-').slice(0, -1);
};

/**
 * Formatting for VIN
 * Letters O (o), I (i), and Q (q) are converted to 0, 1, 9 respectively.
 */
export const formatVIN = (vin: string) => {
  return vin
    .replace(/[o]/gi, '0')
    .replace(/[i]/gi, '1')
    .replace(/[q]/gi, '9')
    .replace(/[^a-z0-9]/gi, '')
    .substr(0, VIN_LENGTH)
    .toUpperCase();
};
