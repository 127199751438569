/* @flow */
import gql from 'graphql-tag';

/**
 * Phone login
 */
export default gql`
  mutation PhoneLogin($input: PhoneLoginInput!) {
    phoneLogin(input: $input) {
      viewer {
        user {
          id
        }
      }
    }
  }
`;
