import moment, { MomentFormatSpecification } from 'moment';
import find from 'lodash/find';
import includes from 'lodash/includes';
import deburr from 'lodash/deburr';
import * as bankAccountValidator from 'us-bank-account-validator';
import cardValidator from 'card-validator';
import { isValid as isValidDriverLicense } from 'usdl-regex';
import { every, isArray, isString, keys, pickBy } from 'lodash';

import statesData from '../static_data/states.json';
import { parseFullName } from '../utils/name';
import { COUNTRY_CODE_ENUMS } from '../enums/country';

const DRIVER_MIN_AGE = 14;
const DRIVER_MAX_AGE = 150;

export const pastYearValidator = {
  isInt: true,
  min: 1700,
  max: moment().year(),
  error: 'Invalid year',
};

export const yearValidator = {
  isInt: true,
  min: 1500,
  max: moment().year(),
  error: 'Invalid year',
};

/**
 * Validator for ageLicensed field
 */
export const ageLicensedValidator = {
  checkAge(age: number) {
    const driverState = (this as any).driverLicenseState
      ? find(statesData, (state) => state.abbreviation === (this as any).driverLicenseState)
      : undefined;
    const minAge = driverState ? driverState.minAge : DRIVER_MIN_AGE;
    const maxAge = (this as any).dateOfBirth ? moment().diff((this as any).dateOfBirth, 'y') : DRIVER_MAX_AGE;

    if (age < minAge || age > maxAge) {
      throw new Error(`Please enter a valid age`);
    }
  },
};

/**
 * Make sure there is a first and last name with at least 2 characters each.
 */
export const validFullname = (val?: string, minimumPartLength = 2): boolean => {
  if (!val) {
    return false;
  }
  const nameObj = parseFullName(val.trim(), 'all', -1, 0, 1);
  const first = (nameObj.first || '').replace(/[.|\d]/g, ' ').trim();
  const last = (nameObj.last || '').replace(/[.|\d]/g, ' ').trim();

  return first.length >= minimumPartLength && last.length >= minimumPartLength;
};

/**
 * Check original name string whether contains invalid letters inside. We allow ascii and Latin Extended-A for now.
 */
export function validInvalidLetters(name?: string): boolean {
  if (!name) {
    return true;
  }
  // \u00C0-\u017F includes Latin Extended-A
  if (deburr(name) !== name || name !== name.replace(/[^a-zA-Z0-9\u00C0-\u017F- ]/g, '')) {
    return false;
  }
  return true;
}

/**
 * Email validation
 */
export const validEmail = (val?: string): boolean => {
  if (!val) {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
};

/**
 * A numeric string of length X
 */
export const validNumeric: (val?: string, len?: number, required?: boolean) => boolean = (
  val,
  len,
  required = true
) => {
  if (!val) {
    return !required;
  }

  const exp = len ? `^[0-9]{${len}}$` : '^[0-9]+$';

  const re = new RegExp(exp);
  return re.test(val);
};

/**
 * Date validation
 */
export const validDate: (
  val?: string | moment.Moment,
  format?: MomentFormatSpecification | null,
  after?: any,
  before?: any
) => boolean = (val, format = 'YYYY-MM-DD', after, before) => {
  if (!val) {
    return false;
  }

  let date = format ? moment(val, format, true).utc() : moment(val).utc();
  if (!date.isValid()) {
    return false;
  }

  date = date.startOf('day');

  if (after && !date.isSameOrAfter(moment(after).utc().startOf('day'))) {
    return false;
  }

  if (before && !date.isSameOrBefore(moment(before).utc().startOf('day'))) {
    return false;
  }

  return true;
};

/**
 * Date length validation
 */
export const validDateLength: (date?: string, format?: string) => boolean = (date, format = 'YYYY-MM-DD') =>
  !!date && date.replace(/[^\d]/g, '').length >= format.replace(/[^\w]/g, '').length;

/*
 * Date of birth validation
 */
export const validDateOfBirth: (date?: string | null, format?: string) => boolean = (date, format = 'YYYY-MM-DD') =>
  !!date && validDate(date, format, '1900-01-01', moment());

/*
 * Check if given birth date is valid to get a license, for a given state
 */
export const validAgeLicensed: (date: string | moment.Moment | null, state: string) => boolean = (date, state) =>
  !!date &&
  moment().diff(date, 'years') >= (find(statesData, (s) => s.abbreviation === state) as { minAge: number }).minAge;

/**
 * Phone validation
 */
export const validPhone = (phone?: string) => validNumeric(phone, 10);

/**
 * Year validation
 */
export const validateYear: (year?: string) => string | null = (year = '') =>
  !year || validNumeric(year, 4, false) ? null : 'Invalid year';

/**
 * Check if driver license is valid.
 *
 * @param number
 * @param state
 */
export const validateDL = ({
  driverLicenseNumber,
  driverLicenseState,
  driverLicenseCountry,
}: {
  driverLicenseNumber: string | null | undefined;
  driverLicenseState: string | null | undefined;
  driverLicenseCountry: string | null | undefined;
}): boolean => {
  if (!driverLicenseCountry || driverLicenseCountry === COUNTRY_CODE_ENUMS.US) {
    if (!driverLicenseState || !driverLicenseNumber) {
      return false;
    }
    try {
      return isValidDriverLicense(driverLicenseState, driverLicenseNumber);
    } catch (e) {
      return false;
    }
  } else {
    // For foreign driver license, only driver license number is required
    return !!driverLicenseNumber;
  }
};

/**
 * Array with non-empty strings validation
 */
export const validArrayString = (array: any) =>
  isArray(array) && every(array, (item) => item && isString(item) && item.trim().length > 0);

/**
 * Check if the given value is a valid credit card number
 * For available card types, see https://github.com/braintree/credit-card-type/blob/master/lib/card-types.js
 * @param {*} value The value to validate
 * @param {*} types Array of accepted card types (defaults to Visa and Mastercard)
 */
export const validCreditCard = (value: string, types: string[] = ['visa', 'mastercard']): boolean => {
  const number = cardValidator.number(value);
  return number.isValid && types.includes(number.card.type);
};

/**
 * Check if the given value is a valid credit card in Master/Visa/Amex/Discover
 * @param value
 * @returns {boolean}
 */
export const validCreditCardWithAmexDiscover = (value: string): boolean =>
  validCreditCard(value, ['visa', 'mastercard', 'american-express', 'discover']);

/**
 * Check if the given value is a valid credit card expiration date
 * The input can be any date format accepted by moment.js
 * @param {*} value The value to validate
 */
export const validExpirationDate = (value: string | moment.Moment): boolean => {
  const date = moment(value).format('MM/YY');
  return cardValidator.expirationDate(date).isValid;
};

/**
 * Check if the given value is a valid credit card CVV
 * @param {*} value The value to validate
 * @param {*} maxLength The max length of valid cvv
 */
export const validCvv = (value: string, maxLength?: number): boolean => cardValidator.cvv(value, maxLength).isValid;

/**
 * Check if the given value is a valid bank account number
 * @param {*} value The value to validate
 */
export const validBankAccount = (value: string): boolean => bankAccountValidator.accountNumber(value).isValid;

/**
 * Check if the given value is a valid bank routing number
 * @param {*} value The value to validate
 */
export const validRoutingNumber = (value: string): boolean => bankAccountValidator.routingNumber(value).isValid;

/**
 * Check if the given value is a valid US zip code
 * @param {*} value The value to validate
 */
export const validZip = (value: string): boolean => validNumeric(value, 5);

/**
 * Check if input args object has only allowed keys
 * @param {*} args The args object passed as mutation input
 * @param {*} allowed The array of allowed key names
 * @return {boolean} result of the check - 'true' if all keys are from array,
 *   'false' if some of the keys are outside of allowed array
 */
export const allowArgs = (args: any, allowed: string[]): boolean =>
  every(keys(pickBy(args, (v) => v !== null)), (arg) => includes(allowed, arg));

export default {
  yearValidator,
};
