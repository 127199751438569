/* @flow */

import React from 'react';
import { css } from 'aphrodite';

import StepContainerStyle from './style';

type Props = {
  style?: Array<any>,
  containerStyle?: Array<any>,
  children: any,
};

/**
 * StepContainer
 */
const StepContainer = (props : Props) => (
  <div className={css(StepContainerStyle.container, ...props.style)}>
    <div className={`container ${css(...props.containerStyle)}`}>
      {props.children}
    </div>
  </div>
);

StepContainer.defaultProps = {
  style: [],
  containerStyle: [],
};

export default StepContainer;

