/* @flow */

import { StyleSheet } from 'aphrodite';
import { xs, sm, md, lg, xl } from './variables';

export default StyleSheet.create({
  fullWidthXs: {
    [`@media (max-width: ${xs}px)`]: {
      width: '100%',
    },
  },
  fullWidthMd: {
    [`@media (max-width: ${md}px)`]: {
      width: '100%',
    },
  },
  horizontalScroll: {
    overflow: 'auto',
  },
  hiddenXs: {
    [`@media (max-width: ${xs}px)`]: {
      display: 'none',
    },
  },
  hiddenSm: {
    [`@media (max-width: ${sm}px)`]: {
      display: 'none',
    },
  },

  hiddenMd: {
    [`@media (max-width: ${md}px)`]: {
      display: 'none',
    },
  },
  hiddenLg: {
    [`@media (max-width: ${lg}px)`]: {
      display: 'none',
    },
  },
  hiddenXl: {
    [`@media (max-width: ${xl}px)`]: {
      display: 'none',
    },
  },
  visibleXs: {
    display: 'none',
    [`@media (max-width: ${xs}px)`]: {
      display: 'inherit',
    },
  },
  visibleSm: {
    display: 'none',
    [`@media (max-width: ${sm}px)`]: {
      display: 'inherit',
    },
  },
  visibleMd: {
    display: 'none',
    [`@media (max-width: ${md}px)`]: {
      display: 'inherit',
    },
  },
  visibleMd2: {
    [`@media (max-width: ${md}px)`]: {
      display: 'inherit',
    },
  },
  visibleLg: {
    display: 'none',
    [`@media (max-width: ${lg}px)`]: {
      display: 'inherit',
    },
  },
  footerMobileContainer: {
    display: 'none',
    [`@media (max-width: ${sm}px)`]: {
      display: 'inline-block',
      width: '100%',
    },
  },
  footerMobileContainerSmall: {
    display: 'none',
    [`@media (max-width: ${sm}px)`]: {
      display: 'inline-block',
      width: 'inherit',
    },
  },
  marginTopMd: {
    marginTop: '16px',
    [`@media (max-width: ${md}px)`]: {
      marginTop: '10vh',
    },
  },
});
