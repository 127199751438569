/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../style/color';
import { fontFamilyRegular, md, borderColor } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  base: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    overflow: 'hidden',
  },
  xlarge: {
    padding: '120px 0',
    [`@media (max-width: ${md}px)`]: {
      padding: '72px 0',
    },
  },
  large: {
    padding: '104px 0',
    [`@media (max-width: ${md}px)`]: {
      padding: '48px 0',
    },
  },
  medium: {
    padding: '48px 0',
    [`@media (max-width: ${md}px)`]: {
      padding: '32px 0',
    },
  },
  small: {
    padding: '32px 0',
    [`@media (max-width: ${md}px)`]: {
      padding: '24px 0',
    },
  },
  noPaddingBottom: {
    paddingBottom: '0',
    [`@media (max-width: ${md}px)`]: {
      paddingBottom: '0',
    },
  },
  none: {
    padding: '0',
    [`@media (max-width: ${md}px)`]: {
      padding: '0',
    },
  },
  white: {
    backgroundColor: Theme2.White,
  },
  gray: {
    backgroundColor: Theme2.GrayXl,
  },
  grayxd: {
    backgroundColor: Theme2.GrayXd,
  },
  black: {
    backgroundColor: Theme2.Black,
  },
  primary: {
    backgroundColor: Theme2.PrimaryXd,
  },
  borderTop: {
    borderTop: `1px solid ${borderColor}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${borderColor}`,
  },
  borderBoth: {
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
  },
});
