/* @flow */

import { StyleSheet } from 'aphrodite';

import { Theme2 } from '../../style/color';
import { fontFamilyRegular } from '../../style/variables';

export default StyleSheet.create({
  /** Base style */
  base: {
    fontFamily: fontFamilyRegular,
    fontWeight: '400',
    fontSize: '16px',
  },
  sizeSmall: {
    fontSize: '12px',
  },
  sizeMedium: {
    fontSize: '14px',
  },
  sizeLarge: {
    fontSize: '16px',
  },
  ul: {
    listStyle: 'none',
  },
  li: {
    display: 'inline-block',
  },
  light: {
    color: Theme2.White,
  },
  mutedLight: {
    color: Theme2.White60,
  },
  dark: {
    color: Theme2.Black,
  },
  mutedDark: {
    color: Theme2.GrayD60,
  },
  vertical: {
    display: 'block',
    margin: '8px 0',
  },
  horizontal: {
    display: 'inline-block',
    margin: '0 8px',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
});
