/* @flow */

import { StyleSheet } from 'aphrodite';

import DefaultColor, { Theme2 } from '../../style/color';
import {
  borderRadius,
  fontFamilyMedium,
  fontFamilyRegular,
  sm,
  md,
  xs,
  transitionDefault,
} from '../../style/variables';
import IconCheck from './imgs/check.svg';

export default StyleSheet.create({
  /** Base style */
  base: {
    fontFamily: fontFamilyMedium,
    fontWeight: '500',
    borderRadius,
    fontSize: '14px',
    transition: transitionDefault,
    display: 'block',
    border: '2px solid transparent',
    position: 'relative',
    cursor: 'pointer',
    padding: '16px 32px',
    minWidth: '120px',
    ':focus': {
      outline: 'none',
    },
  },
  small: {
    padding: '12px 16px',
    minWidth: 'auto',
  },
  extraSmall: {
    padding: '8px 12px',
    minWidth: 'auto',
  },
  primary: {
    backgroundColor: Theme2.PrimaryXd,
    color: Theme2.White,
    ':hover': {
      backgroundColor: Theme2.PrimaryD,
    },
    ':focus': {
      backgroundColor: Theme2.PrimaryD,
    },
    ':visited': {
      backgroundColor: Theme2.PrimaryD,
    },
    ':active': {
      backgroundColor: Theme2.PrimaryD,
    },
  },
  secondary: {
    backgroundColor: Theme2.SecondaryXd,
    color: Theme2.White,
    ':hover': {
      backgroundColor: Theme2.SecondaryD,
    },
    ':focus': {
      backgroundColor: Theme2.SecondaryD,
    },
    ':visited': {
      backgroundColor: Theme2.SecondaryD,
    },
    ':active': {
      backgroundColor: Theme2.SecondaryD,
    },
  },

  light: {
    fontFamily: fontFamilyRegular,
    backgroundColor: Theme2.White,
    border: '1px solid',
    borderColor: Theme2.Black10,
    color: Theme2.Black,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },

  ghost: {
    backgroundColor: 'transparent',
  },

  border: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: Theme2.Black10,
    color: Theme2.Black60,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },

  borderWhite: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: DefaultColor.White,
    color: DefaultColor.White,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },

  borderGrayD: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: Theme2.GrayM,
    color: Theme2.GrayD,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
    [`@media (max-width: ${sm}px)`]: {
      borderColor: Theme2.GrayD,
      color: Theme2.GrayXd,
    },
  },

  borderRed: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: Theme2.PrimaryXd,
    color: Theme2.PrimaryXd,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },

  borderRedCheck: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: Theme2.PrimaryXd,
    color: Theme2.PrimaryXd,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
    '::before': {
      content: '" "',
      backgroundImage: `url(${IconCheck})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '16px',
      height: '16px',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
    },
  },

  borderRedCheckSm: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: Theme2.PrimaryXd,
    color: Theme2.PrimaryXd,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
    [`@media (min-width: ${sm}px)`]: {
      '::before': {
        content: '" "',
        backgroundImage: `url(${IconCheck})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '16px',
        height: '16px',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
      },
    },
  },

  noBorderLight: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'transparent',
    color: Theme2.GrayD,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },
  borderWhiteFill: {
    fontFamily: fontFamilyRegular,
    fontWeight: '300',
    backgroundColor: 'white',
    borderRadius: '0px 0px 10px 10px',
    border: '1px solid',
    borderColor: Theme2.Black10,
    color: Theme2.Black60,
    ':hover': {
      opacity: 0.7,
    },
    ':focus': {
      opacity: 0.7,
    },
    ':visited': {
      opacity: 0.7,
    },
    ':active': {
      opacity: 0.7,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: Theme2.GrayD,
    color: Theme2.White,
    ':hover': {
      backgroundColor: Theme2.GrayD,
    },
    ':focus': {
      backgroundColor: Theme2.GrayD,
    },
    ':visited': {
      backgroundColor: Theme2.GrayD,
    },
    ':active': {
      backgroundColor: Theme2.GrayD,
    },
  },

  disabledBorder: {
    backgroundColor: 'transparent',
    color: Theme2.GrayM,
    borderColor: Theme2.Black10,
    opacity: 0.65,
    ':hover': {
      opacity: 0.5,
      backgroundColor: 'transparent',
    },
    ':focus': {
      opacity: 0.5,
      backgroundColor: 'transparent',
    },
    ':visited': {
      opacity: 0.5,
      backgroundColor: 'transparent',
    },
    ':active': {
      opacity: 0.5,
      backgroundColor: 'transparent',
    },
  },

  facebook: {
    backgroundColor: '#3B5998',
    color: Theme2.White,
    ':hover': {
      backgroundColor: '#344E86',
    },
    ':focus': {
      backgroundColor: '#344E86',
    },
    ':visited': {
      backgroundColor: '#344E86',
    },
    ':active': {
      backgroundColor: '#344E86',
    },
  },

  inline: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
  },
  flex: {
    flex: '1 1 auto',
  },
  none: {
    display: 'none',
  },
  autowidth: {
    width: 'auto',
  },
  fullwidth: {
    width: '100%',
  },
  mobilefullwidth: {
    [`@media (max-width: ${sm}px)`]: {
      width: '100%',
    },
  },
  mobilefullwidthXs: {
    [`@media (max-width: ${xs}px)`]: {
      width: '100%',
    },
  },
  mobileNoBorderRadiusSM: {
    [`@media (max-width: ${sm}px)`]: {
      borderRadius: 0,
    },
  },
  mobileNoBorderRadiusMd: {
    [`@media (max-width: ${md}px)`]: {
      borderRadius: 0,
    },
  },
  mobileNoBorderRadiusXs: {
    [`@media (max-width: ${xs}px)`]: {
      borderRadius: 0,
    },
  },
  black: {
    color: Theme2.Black,
    fontWeight: '500',
  },
  roundedBorder: {
    borderRadius,
    [`@media (max-width: ${sm}px)`]: {
      borderRadius,
    },
    [`@media (max-width: ${md}px)`]: {
      borderRadius,
    },
    [`@media (max-width: ${xs}px)`]: {
      borderRadius,
    },
  },
});
