/* @flow */

import { padString } from '../utils/general';

/**
 * Linear interpolation
 */
const interpolate = (a: number, b: number, t: number): number => {
  const val = a * t + (1 - t) * b;
  return val;
};

/**
 * Convert hex string to 8bit RGB component integers
 */
const parseHex = (hex: string): Array<number> => {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);

  return [r, g, b];
};

/**
 * Converts a hex color to comma separated RGB values.
 * Example: '#0080C0' -> '0, 128, 192'
 */
export const hexToRGB = (hex: string): string => {
  const [r, g, b] = parseHex(hex);
  return `${r}, ${g}, ${b}`;
};

/**
 * Lighten a color by a percent
 */
export const lighten = (hex: string, percent: number): string => {
  const t = percent / 100;

  let [r, g, b] = parseHex(hex);
  r = Math.min(255, Math.max(0, Math.round(interpolate(255, r, t)))).toString(16);
  g = Math.min(255, Math.max(0, Math.round(interpolate(255, g, t)))).toString(16);
  b = Math.min(255, Math.max(0, Math.round(interpolate(255, b, t)))).toString(16);

  return `#${padString(r, 2)}${padString(g, 2)}${padString(b, 2)}`;
};

/**
 * Darken a color by a percent
 */
export const darken = (hex: string, percent: number): string => {
  const t = percent / 100;

  let [r, g, b] = parseHex(hex);
  r = Math.min(255, Math.max(0, Math.round(interpolate(0, r, t)))).toString(16);
  g = Math.min(255, Math.max(0, Math.round(interpolate(0, g, t)))).toString(16);
  b = Math.min(255, Math.max(0, Math.round(interpolate(0, b, t)))).toString(16);

  return `#${padString(r, 2)}${padString(g, 2)}${padString(b, 2)}`;
};

/**
 * New color theme
 */
export const Theme2 = {
  Black: '#0D202F',
  Black60: `rgba(${hexToRGB('#0D202F')}, 0.6)`,
  Black40: `rgba(${hexToRGB('#0D202F')}, 0.4)`,
  Black20: `rgba(${hexToRGB('#0D202F')}, 0.2)`,
  Black10: `rgba(${hexToRGB('#0D202F')}, 0.1)`,
  White: '#fff',
  White80: `rgba(${hexToRGB('#FFFFFF')}, 0.8)`,
  White60: `rgba(${hexToRGB('#FFFFFF')}, 0.6)`,
  White40: `rgba(${hexToRGB('#FFFFFF')}, 0.4)`,
  White20: `rgba(${hexToRGB('#FFFFFF')}, 0.2)`,
  White10: `rgba(${hexToRGB('#FFFFFF')}, 0.1)`,
  PrimaryXd: '#F43543',
  PrimaryXd10: `rgba(${hexToRGB('#F43543')}, 0.1)`,
  PrimaryD: '#F4505D',
  PrimaryM: '#F27781',
  PrimaryL: '#F99DA4',
  PrimaryXl: '#FFE3E5',
  GrayXd: '#3B4F60',
  GrayXd60: `rgba(${hexToRGB('#3B4F60')}, 0.6)`,
  GrayXd50: `rgba(${hexToRGB('#3B4F60')}, 0.5)`,
  GrayXd40: `rgba(${hexToRGB('#3B4F60')}, 0.4)`,
  GrayXd30: `rgba(${hexToRGB('#3B4F60')}, 0.3)`,
  GrayXd20: `rgba(${hexToRGB('#3B4F60')}, 0.2)`,
  GrayXd10: `rgba(${hexToRGB('#3B4F60')}, 0.1)`,
  GrayD: '#6B7A86',
  GrayD60: `rgba(${hexToRGB('#6B7A86')}, 0.6)`,
  GrayM: '#A5AEB5',
  GrayL: '#CAD0D4',
  GrayXl: '#F5F6F7',
  Green10: `rgba(${hexToRGB('#5BC236')}, 0.1)`,
  SecondaryXd: '#114661',
  SecondaryD: '#3D7591',
  SecondaryM: '#70A7C4',
  SecondaryL: '#7DBAD8',
  SecondaryXl: '#E0F1FA',
};

/**
 * Color definitions
 */
export default {
  White: '#FFFFFF',
  White50: `rgba(${hexToRGB('#FFFFFF')}, 0.5)`,
  White20: `rgba(${hexToRGB('#FFFFFF')}, 0.2)`,
  White10: `rgba(${hexToRGB('#FFFFFF')}, 0.1)`,
  Gray: '#F2F2F2',
  Gray70: '#3E4043',
  Gray60: '#6F7276',
  Gray50: '#9C9FA4',
  Gray40: '#E0E0E0',
  Black: '#494949',
  Black60: `rgba(${hexToRGB('#494949')}, 0.6)`,
  Black40: `rgba(${hexToRGB('#494949')}, 0.4)`,
  Black20: `rgba(${hexToRGB('#494949')}, 0.2)`,
  Black10: `rgba(${hexToRGB('#494949')}, 0.1)`,
  PrimaryDefault: '#FFC600',
  PrimaryLight: '#F5F5F5',
  PrimaryDark: '#E2B310',
  Red: '#FF6666',

  // Custom colors for task status notifications and indicators
  ErrorBg: '#F99DA4',
  Success: '#4CAF50',
  Running: '#CDDC39',
  Clean: '#c6d9e4',
  Error: '#F44336',
  Undefined: '#9E9E9E',
};
