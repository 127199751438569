/* @flow */

import { StyleSheet } from 'aphrodite';
import _ from 'underscore';
import { Theme2 } from '../../style/color';
import { transitionDefault } from '../../style/variables';

import IconsEOT from '../../fonts/jerry-mini.eot';
import IconsSVG from '../../fonts/jerry-mini.svg';
import IconsTTF from '../../fonts/jerry-mini.ttf';
import IconsWOFF from '../../fonts/jerry-mini.woff';
import IconsWOFF2 from '../../fonts/jerry-mini.woff2';

const JerryMini = {
  fontFamily: 'jerry-mini',
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: `url(${IconsEOT}) format('embedded-opentype'), url(${IconsWOFF2}) format('woff2'), url(${IconsWOFF}) format('woff'), url(${IconsTTF}) format('truetype'), url(${IconsSVG}) format('svg')`,
};

const iconTypes = {
  StreUp: {
    '::before': {
      content: '"\uea09"',
    },
  },
  StreDown: {
    '::before': {
      content: '"\uea06"',
    },
  },
  StreLeft: {
    '::before': {
      content: '"\uea07"',
    },
  },
  StreRight: {
    '::before': {
      content: '"\uea08"',
    },
  },
  TriangleUp: {
    '::before': {
      content: '"\uea0c"',
    },
  },
  TriangleDown: {
    '::before': {
      content: '"\uea0f"',
    },
  },
  TailRight: {
    '::before': {
      content: '"\uea02"',
    },
  },
  Pen01: {
    '::before': {
      content: '"\uea16"',
    },
  },
  PhoneCall: {
    '::before': {
      content: '"\uea14"',
    },
  },
  ZoomSplit: {
    '::before': {
      content: '"\uea13"',
    },
  },
  Email83: {
    '::before': {
      content: '"\uea17"',
    },
  },
  Check2: {
    '::before': {
      content: '"\uea1c"',
    },
  },
  SimpleRemove: {
    '::before': {
      content: '"\uea1d"',
    },
  },
  Menu34: {
    '::before': {
      content: '"\uea1e"',
    },
  },
  FbSimple2: {
    '::before': {
      content: '"\uea1f"',
    },
  },
  Twitter2: {
    '::before': {
      content: '"\uea20"',
    },
  },
  Link72: {
    '::before': {
      content: '"\uea21"',
    },
  },
  TailLeft: {
    '::before': {
      content: '"\uea03"',
    },
  },
  TailUp: {
    '::before': {
      content: '"\uea05"',
    },
  },
  TailDown: {
    '::before': {
      content: '"\uea04"',
    },
  },
  Lock: {
    '::before': {
      content: '"\uea22"',
    },
  },
  Quote: {
    '::before': {
      content: '"\uea23"',
    },
  },
  MiniCar2: {
    '::before': {
      content: '"\uea28"',
    },
  },
  MiniHome52: {
    '::before': {
      content: '"\uea29"',
    },
  },
  SimpleAdd: {
    '::before': {
      content: '"\uea1a"',
    },
  },
  MiniAlertCircleI2: {
    '::before': {
      content: '"\uea45"',
    },
  },
  MiniShapeStar1: {
    '::before': {
      content: '"\uea47"',
    },
  },
  MiniShapeStar2: {
    '::before': {
      content: '"\uea48"',
    },
  },
  Support16: {
    '::before': {
      content: '"\uea5a"',
    },
  },
  SingleCopy04: {
    '::before': {
      content: '"\uea57"',
    },
  },
  EventConfirm: {
    '::before': {
      content: '"\uea66"',
    },
  },
  MobileButton: {
    '::before': {
      content: '"\uea67"',
    },
  },
  CustomerSupport: {
    '::before': {
      content: '"\uea68"',
    },
  },
  TailRight3: {
    '::before': {
      content: '"\uea5d"',
    },
  },
  TailUp2: {
    '::before': {
      content: '"\uea6f"',
    },
  },
  Like1: {
    '::before': {
      content: '"\uea69"',
    },
  },
  LikeNo1: {
    '::before': {
      content: '"\uea6a"',
    },
  },
  Like2: {
    '::before': {
      content: '"\uea6c"',
    },
  },
  LikeNo2: {
    '::before': {
      content: '"\uea6b"',
    },
  },
  ERemove: {
    '::before': {
      content: '"\uea6d"',
    },
  },
  IRemove: {
    '::before': {
      content: '"\uea6e"',
    },
  },
  CCheck: {
    '::before': {
      content: '"\uea70"',
    },
  },
  Edit75: {
    '::before': {
      content: '"\uea4c"',
    },
  },
};

export const IconValues: {[string]: string} = _.reduce(_.keys(iconTypes),
  (m, val) => ({
    ...m,
    [val]: val,
  }), {});

export default StyleSheet.create({
  /** Base style */
  base: {
    display: 'inline-block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    lineHeight: 1,
    fontFamily: [JerryMini],
    fontSize: 'inherit',
    speak: 'none',
    textTransform: 'none',
    fontSmooth: 'auto',
  },
  small: {
    fontSize: '12px',
  },
  medium: {
    fontSize: '14px',
  },
  large: {
    fontSize: '16px',
  },
  xlarge: {
    fontSize: '20px',
  },
  smallPad: {
    padding: '4px',
  },
  mediumPad: {
    padding: '8px',
  },
  largePad: {
    padding: '12px',
  },
  xlargePad: {
    padding: '16px',
  },
  block: {
    display: 'block',
  },
  inline: {
    display: 'inline-block',
  },
  flex: {
    display: 'flex',
  },
  white: {
    color: Theme2.White,
  },
  whiteMuted: {
    color: Theme2.White60,
  },
  black: {
    color: Theme2.Black,
  },
  blackMuted: {
    color: Theme2.Black60,
  },
  blackExtraMuted: {
    color: Theme2.GrayD,
  },
  gray: {
    color: Theme2.GrayXd,
  },
  grayMuted: {
    color: Theme2.GrayXd60,
  },
  primary: {
    color: Theme2.PrimaryXd,
  },
  hover: {
    transition: transitionDefault,
    ':hover': {
      color: Theme2.PrimaryXd,
      opacity: '1',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  ...iconTypes,
});
